import React from 'react';
import propTypes from 'prop-types';
import {Alert, AlertTitle, Checkbox,
  FormControlLabel, FormGroup, Typography} from '@mui/material';
import {Box} from '@mui/system';

/**
 * Warning message that show only when the user doesn't have enough tokens
 * @param {object} formik
 * @param {object} eventDetail
 * @return {jsx}
 */
function NotEnoughTokensWarning({formik}) {
  const notEnoughTokens = formik.values.notEnoughTokens;

  return (
    <Box>
      <Alert severity="warning">
        <AlertTitle>
            Attention, l&apos;adresse a été modifiée.
        </AlertTitle>
        <FormGroup>
          <FormControlLabel control={<Checkbox
            id='recalculate'
            name='recalculate'
            disabled={notEnoughTokens}
            value={formik.values.recalculate}
            onChange={formik.handleChange}
            sx={{
              'color': '#ED6B39',
              '&.Mui-checked': {
                'color': '#ED6B39',
              },
            }}/>}
          label="Recalculer la distance pour tous les formulaires." />
        </FormGroup>
        {notEnoughTokens ? (
            <Typography className="not-enough-tokens">
              Vous ne pouvez effectuer ce calcul
              puisque vous n&apos;avez plus de jetons.
              Merci de contacter le CQEER pour régler la situation.
            </Typography>
          ) : null}
      </Alert>
    </Box>
  );
}


NotEnoughTokensWarning.propTypes = {
  formik: propTypes.object,
  eventDetail: propTypes.object,
};

export default NotEnoughTokensWarning;
