import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import useLoading from '../../global/components/loader/useLoading';
import usersService from '../services/users';
/**
 * Costum hook for userList operation
 * @return {object}
 */
function useUsersList() {
  const [usersList, setUsersList] = useState([]);
  const navigate = useNavigate();
  const {setIsLoading} = useLoading();

  /**
   * Fonction qui navigue vers la vu détails d'un utilisateur
   * @param {*} id Le id de l'utilisateur
   */
  function onView(id) {
    const path = `/users/${id}/details`;
    navigate(path);
  }

  /**
   * Fonction qui navigue vers la vue de modification d'un utilisateur
   * @param {string} id L'identifiant de l'utilisateur.
   */
  function onEdit(id) {
    const path = `/users/${id}/edit`;
    navigate(path);
  }


  /**
   * Permet de supprimer un utilisateur
   * @param {string} id L'identifiant de l'utilisateur.
   */
  function onDelete(id) {
    setIsLoading(true);
    usersService.deleteUser(id).then(() => {
      loadUsersList();
      navigate('/users');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  /**
   * Permet de charger la liste des utilisateurs
   */
  function loadUsersList() {
    setIsLoading(true);
    usersService.getAllUsers().then((res) => {
      setUsersList(res.data);
    }).finally(()=>{
      setIsLoading(false);
    });
  };

  useEffect(() => {
    loadUsersList();
  }, []);

  return {onView, onEdit, onDelete, usersList};
}

export default useUsersList;
