import axios from 'axios';
import responsesService from '../../responses/services/responses';
import {dateTimeFormatOption} from '../../global';
const Excel = require('exceljs');

/**
 * Créer un template pour export un fichier excel
 * pour la liste des formulaires d'un évènement
 * @param {*} id l'id du formulaire
 * @author Samuel Barriault
 */
async function buildExcelFile(id) {
  axios.get('/excel/forms', {responseType: 'arraybuffer'}).then((res) => {
    const excelData = res.data;
    const w = new Excel.Workbook();
    w.xlsx.load(excelData).then((workbook) => {
      const worksheet = workbook.worksheets[0];
      const table = worksheet.addTable({
        name: 'Tableau1',
        ref: 'A1',
        headerRow: true,
        style: {
          theme: 'TableStyleLight7',
          showRowStripes: true,
        },
        rows: [],
        columns: [
          {name: worksheet.getCell('A1').text, filterButton: true},
          {name: worksheet.getCell('B1').text, filterButton: true},
          {name: worksheet.getCell('C1').text, filterButton: true},
          {name: worksheet.getCell('D1').text, filterButton: true},
          {name: worksheet.getCell('E1').text, filterButton: true},
          {name: worksheet.getCell('F1').text, filterButton: true},
        ],
      });

      responsesService.getAllResponses(id).then((res) => {
        const batchSize = 100; // Nombre de lignes ajoutées par lot
        const data = res.data;
        const totalRows = data.length;

        for (let i = 0; i < totalRows; i += batchSize) {
          const batchData = data.slice(i, i + batchSize);

          batchData.forEach((item) => {
            table.addRow([
              item.transport.name.fr,
              item.start_point,
              item.distance,
              item.generated_ges,
              item.passengers_number,
              new Date(item.created_at)
                  .toLocaleString('en-CA', dateTimeFormatOption),
            ]);
          });

          // Appliquer le style aux cellules pour le lot actuel
          const columns = ['A', 'B', 'C', 'D', 'E'];
          columns.forEach((c) => {
            for (let j = i + 2; j < i + 2 + batchData.length; j++) {
              const cell = worksheet.getCell(c + j);
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
              cell.font = {
                color: {argb: '00000000'},
              };
            }
          });
        }

        table.commit();

        workbook.xlsx.writeBuffer().then((buffer) => {
          const url = URL.createObjectURL(new Blob([buffer]));
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'Formulaire.xlsx';
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        });
      });
    });
  });
}

const exportFormulaire = {
  buildExcelFile,
};

export default exportFormulaire;
