import React, {useState} from 'react';
import Button from '@mui/material/Button';
import './ExcelModalImport.scss';
// import myExcelFile from './importUtils/ExcelFileGlobal';
import ModalImportExcel from '../../modals/modalImportExcel/ModalImportExcel';
import * as PropType from 'prop-types';

/**
 * Fonction principale d'affichage du modal et
 * Du traitement après l'importation du fichier Excel
 * @param {Function} reloadList permet de recharger la liste des formulaires
 * @return {JSX.Element}
 */
function ExcelImport({reloadList}) {
  const [openModal, setOpenModal] = useState(false);
  /*
  * Ces constantes sont là pour modifier le dôme virtuel
  */
  const [fileName, setFileName] = useState('Choisir votre fichier');

  /*
  * Utilisation d'une variable global initialisée dans ExcelFileGlobal
  * Pour stocker le fichier en attendant l'action de l'utilisateur
  */
  const [actualFile, setActualFile] = useState(null);

  /*
  * Ces fonctions permettent d'ouvrir et de fermer le modal et
  * La zone du snackbar
  */
  const handleModalOpen = () => setOpenModal(true);

  /*
  * Ferme le modal, vide la variable global et
  * Réinitialise le texte du fichier affiché
  */
  const handleModalClose = () => {
    setOpenModal(false);
    setActualFile(null);
    setFileName('Choisir votre fichier');
  };

  return (
    <div className="Excel">
      {/* Bouton pouvant ouvrir le modal d'importation du fichier Excel */}
      <Button
        data-testid="btnEditEvent"
        type="button"
        variant='contained'
        onClick={handleModalOpen} >
          Importer
      </Button>

      {/* L'affichage du modal */}
      <ModalImportExcel
        openModal={openModal}
        handleModalClose={handleModalClose}
        fileName={fileName}
        setFileName={setFileName}
        setActualFile={setActualFile}
        actualFile={actualFile}
        reloadList = {reloadList}
      />
    </div>
  );
};

ExcelImport.propTypes = {
  reloadList: PropType.func,
};

export default ExcelImport;

