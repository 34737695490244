import React, {useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import transportsService from '../services/transports';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useTransportList from '../hooks/useTransportList';
import propTypes from 'prop-types';
import {useMediaQuery} from '@mui/material';
import {ModalConfirmDelete} from '../../global/modals/ModalConfirmDelete';

/**
 * Le component de liste de véhicules
 * Contenu type de l'objet du array
    'name': {
      'fr': '',
      'en': '',
    },
    'type': {
      'google_id': 1,
      'type_id': 1,
    },
    'avg_distance_km': 0,
    'color': '#333333',
    'ges_rate': 0,
    'info': '',
    'avg_distance_km': '',
 * @return {JSX.Element}
 */
function TransportsList() {
  const {onView, onEdit, onDelete, transportsList} = useTransportList();
  const [popup, setPopup] = useState({
    title: '',
    openDeleteModal: false,
    idtoDelete: null,
    action: null,
  });

  const transportTypes = transportsService.getAllTransportTypes();
  const findTransportName = (id) => {
    const transport = transportTypes.find((type) => type.id === id);
    return transport ? transport.name_fr:'';
  };

  const matchesSm = !useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const matchesMd = !useMediaQuery((theme) => theme.breakpoints.up('md'));

  const columns = [
    {
      field: 'name',
      headerName: 'Mode',
      flex: 1,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.name.fr,
      renderCell: (params) => <p className='longName'>{params.row.name.fr}</p>,
    },
    {
      field: 'ges_rate',
      headerName: 'Taux d\'émission de GES',
      hide: matchesMd,
      width: 200,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => params.row.ges_rate,
      renderCell: (params) => `${params.row.ges_rate} kg`,
    },
    {
      field: 'type',
      headerName: 'Catégorie de transport',
      hide: matchesSm,
      width: 200,
      headerAlign: 'center',
      cellClassName: 'MuiDataGrid-cell--textCenter',
      valueGetter: (params) => findTransportName(params.row.type.type_id),
      renderCell: (params) => findTransportName(params.row.type.type_id),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 200,
      renderCell: (params) => {
        return (
          <div className="buttons-container">
            <IconButton
              data-testid={'eyeIconDetailEvent'}
              aria-label="Accéder au transport"
              onClick={()=> onView(params.row.id)}
              edge="end"
              sx={{color: '#ED6B39'}}>
              <VisibilityIcon/>
            </IconButton>
            <IconButton
              aria-label="Modifier le véhicule"
              onClick={() => onEdit(params.row.id)}
              edge="end"
              sx={{color: '#ED6B39'}}>
              <EditIcon/>
            </IconButton>
            <IconButton
              data-testid='btnDelete'
              aria-label="Supprimer le véhicule"
              onClick={() => setPopup({
                title: 'Voulez-vous supprimer ce moyen de transport ?',
                idToDelete: params.row.id,
                openDeleteModal: true,
                action: onDelete,
              }) }
              edge="end"
              sx={{color: '#ED6B39'}}>
              <DeleteIcon/>
            </IconButton>
          </div>
        );
      },
    },
  ];

  return transportsList.length == 0 ? null : (
    <div data-testid="TransportsList" className='list-rows'>
      <div style={{height: '100%'}}>
        <DataGrid autoHeight style={{border: 'none'}}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-cell': {
              borderBottomColor: 'primary.main',
              lineHeight: 'unset !important',
              maxHeight: 'none !important',
              whiteSpace: 'normal',
              textAlign: 'center',
              wordBreak: 'break-word !important',
            },
            '& .MuiDataGrid-row': {
              maxHeight: 'none !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottomColor: 'primary.main',
              textAlign: 'center',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          rows={transportsList}
          columns={columns}
          pageSize={50}
          rowsPerPageOptions={[50]}
          disableVirtualization={process.env.REACT_APP_MODE === 'test'}
        />
      </div>
      <ModalConfirmDelete popup={popup}/>
    </div>
  );
}
TransportsList.propTypes = {
  onView: propTypes.func,
  onEdit: propTypes.func,
  transportsList: propTypes.array,
};
export default TransportsList;
