import React, {useEffect, useState} from 'react';
import transportsServices from '../services/transports';
import transportsService from '../services/transports';
import TransportForm from '../components/TransportForm';
import useTransportForm from '../hooks/useTransportForm';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router';
import useLoading from '../../global/components/loader/useLoading';
import {createEmptyTransport} from '../factory/transport';
import {Container, Typography} from '@mui/material';

/**
 * Page de modification d'un véhicule
 * @return {JSX.Element}
 */
export default function EditTransportPage() {
  const navigate = useNavigate();
  const {id} = useParams();
  const {setIsLoading} = useLoading();
  const [initialValue, setInitialValue] = useState(createEmptyTransport());

  /**
   * Fonction pour soumettre le formulaire de modification
   * @param {*} values Les valeurs entrées dans formulaire
   */
  function submitAction(values) {
    setIsLoading(true);
    transportsServices.sendEditTransport(id, values) .then(() => {
      navigate('/transports');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    setIsLoading(true);
    transportsService.getTransport(id).then((res) => {
      setInitialValue({
        name_fr: res.data.name.fr,
        name_en: res.data.name.en,
        ges_rate: res.data.ges_rate,
        vehicle_google_enumeration_id: res.data.type.google_id,
        color: res.data.color,
        type_id: res.data.type.type_id,
        avg_distance_km: res.data.avg_distance_km,
        info: res.data.info || '',
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  const transportHook = useTransportForm(submitAction, initialValue);

  return (
    <Container data-testid='events-page'>
      <Typography variant={'h1'} data-testid='EditTransport'>
        Modifier un véhicule
      </Typography>
      <TransportForm
        submitText='Modifier le véhicule'
        {...transportHook}
      />
    </Container>
  );
}
