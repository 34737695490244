/**
 * Génère un utilisateur vide
 * @return {object} New empty user for the form
 */
export function createEmptyTransport() {
  return {
    'name_fr': '',
    'name_en': '',
    'ges_rate': '',
    'vehicle_google_enumeration_id': '',
    'color': '',
    'type_id': '',
    'avg_distance_km': '',
    'info': '',
  };
}
