import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import useLoading from '../../global/components/loader/useLoading';
import eventsService from '../services/events';

/**
 * Costum hook for EventList operation
 * @return {object}
 */
function useEventList() {
  const navigate = useNavigate();
  const [eventsList, setEventsList] = useState([]);
  const {setIsLoading} = useLoading();

  /**
   * Fonction qui navigue vers la vu détails d'un utilisateur
   * @param {*} id Le id de l'utilisateur
   */
  function onView(id) {
    const path = `/events/${id}/details`;
    navigate(path);
  }

  /**
   * Fonction qui navigue vers la vue de modification d'un utilisateur
   * @param {string} id L'identifiant de l'utilisateur.
   */
  function onArchive(id) {
    setIsLoading(true);
    eventsService.archiveEvent(id).then(() => {
      reloadEventList();
      navigate('/events');
    }).finally(() => {
      setIsLoading(false);
    });
  }


  /**
   * Permet de supprimer un utilisateur
   * @param {string} id L'identifiant de l'utilisateur.
   */
  function onDelete(id) {
    setIsLoading(true);
    eventsService.deleteEvent(id).then(() => {
      reloadEventList();
      navigate('/events');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  /**
   * Permet de recharger la liste des véhicules
   */
  function reloadEventList() {
    setIsLoading(true);
    eventsService.getAllEvents().then((res)=> {
      setEventsList(res.data);
    }).finally(()=>{
      setIsLoading(false);
    });
  }

  useEffect(() => {
    reloadEventList();
  }, []);


  return {onView, onArchive, onDelete, eventsList};
}

export default useEventList;
