import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ModalConfirm from './ModalConfirm';
import propTypes from 'prop-types';


/**
 * build a modal confirm for user suppression
 * @param {function} popup
 * {
 *   title:
 *   idToDelete:
 *   openDeleteModal:
 *   action:
 * }
 * @return {object}
 */
export function ModalConfirmDelete({popup}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteCheckBoxValue, setDeleteCheckBoxValue] = useState(false);
  const [IdToDelete, setIdToDelete] = useState(undefined);

  useEffect(()=>{
    setIdToDelete(popup.idToDelete);
    setOpenDeleteModal(popup.openDeleteModal);
  }, [popup]);

  return (
    <ModalConfirm
      title={popup.title}
      actionConfirm={() => {
        popup.action(IdToDelete);
        setDeleteCheckBoxValue(false);
        setOpenDeleteModal(false);
      }
      }
      handleClose={() => {
        setDeleteCheckBoxValue(false);
        setOpenDeleteModal(false);
      }}
      open={openDeleteModal}
      disableConfirmButton={!deleteCheckBoxValue}
    >
      <FormGroup sx={{mt: 2}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={deleteCheckBoxValue}
              onChange={
                () => setDeleteCheckBoxValue((prevValue) =>!prevValue)
              }
              sx={{
                'color': '#ED6B39',
                '&.Mui-checked': {color: '#ED6B39'},
              }}
            />
          }
          label="Je suis conscient que cette action est irréversible."
        />
      </FormGroup>
    </ModalConfirm>
  );
}
ModalConfirmDelete.propTypes = {
  popup: propTypes.object,
};

export default ModalConfirmDelete;
