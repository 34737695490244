import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import useLoading from '../../global/components/loader/useLoading';
import transportsService from '../services/transports';
/**
 * Costum hook for transportList operation
 * @return {object}
 */
function useTransportList() {
  const [transportsList, setTransportsList] = useState([]);
  const navigate = useNavigate();
  const {setIsLoading} = useLoading();

  /**
   * Fonction qui navigue vers la vu détails d'un utilisateur
   * @param {*} id Le id de l'utilisateur
   */
  function onView(id) {
    const path = `/transports/${id}/details`;
    navigate(path);
  }

  /**
   * Fonction qui navigue vers la vue de modification d'un utilisateur
   * @param {string} id L'identifiant de l'utilisateur.
   */
  function onEdit(id) {
    const path = `/transports/${id}/edit`;
    navigate(path);
  }


  /**
   * Permet de supprimer un utilisateur
   * @param {string} id L'identifiant de l'utilisateur.
   */
  function onDelete(id) {
    setIsLoading(true);
    transportsService.deleteTransport(id).then(() => {
      reloadTransportList();
      navigate('/transports');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  /**
   * Permet de recharger la liste des véhicules
   */
  function reloadTransportList() {
    setIsLoading(true);
    transportsService.getAllTransports().then((res)=> {
      setTransportsList(res.data);
    }).finally(()=>{
      setIsLoading(false);
    });
  }

  useEffect(() => {
    reloadTransportList();
  }, []);


  return {onView, onEdit, onDelete, transportsList};
}

export default useTransportList;
