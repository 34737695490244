import React, {useState} from 'react';
import userServices from '../services/users';
import UserForm from '../components/UserForm';
import useUserForm from '../hooks/useUserForm';
import {useNavigate} from 'react-router-dom';
import useLoading from '../../global/components/loader/useLoading';
import {createEmptyUser} from '../factory/user';
import AlertMessage from '../components/AlertMessage';
import {Container, Typography} from '@mui/material';
/**
 * Page de création d'un utilisateur
 * @return {JSX.Element}
 */
export default function CreateUserPage() {
  const navigate = useNavigate();
  const {setIsLoading} = useLoading();
  const [alert, setAlert] = useState(undefined);

  /**
   * Création d'un utilisateur
   * @param {*} values les valeurs du formulaire
   */
  function submitAction(values) {
    setIsLoading(true);

    userServices.sendCreateUser(values).then(() => {
      setAlert(undefined);
      navigate('/users');
    }).catch(() => {
      setAlert({
        severity: 'error',
        message: 'Cet utilisateur existe déjà.',
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const userHook = useUserForm(submitAction, createEmptyUser(), 'create');

  return (
    <Container>
      <Typography variant={'h1'} >Ajouter un utilisateur</Typography>
      <UserForm
        submitText='Créer le compte'
        {...userHook}
      />
      <AlertMessage alert={alert} />
    </Container>
  );
}
