import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Navbar from '../components/navbar';
import EventsListPage from '../../events/pages/EventsListPage';
import EventDetailPage from '../../events/pages/EventDetailPage';
import EventCreationPage from '../../events/pages/EventCreationPage';
import EventModificationPage from '../../events/pages/EventModificationPage';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {frFR} from '@mui/x-data-grid';
import {frFR as coreFR} from '@mui/material/locale';
import UsersPage from '../../users/pages/UsersListPage';
import DetailsUser from '../../users/pages/DetailUserPage';
import CreateUser from '../../users/pages/CreateUserPage';
import EditUser from '../../users/pages/EditUserPage';
import TransportsPage from '../../transports/pages/TransportsListPage';
import CreateTransportPage from '../../transports/pages/CreateTransportPage';
import EditTransportPage from '../../transports/pages/EditTransportPage';
import EditProfilePage from '../../users/pages/EditProfilePage';
import DetailTransportPage from '../../transports/pages/DetailTransportPage';
import ResponseFormPage from '../../responses/pages/ResponseFormPage';
import ResponsesListPage from '../../responses/pages/ResponsesListPage';
import ThanksPage from '../../responses/pages/ThanksPage';
import QRCodePage from '../pages/qrCode';
import LangContextProvider from '../contexts/LangContext';
import SearchPage from '../../searchs/pages/SearchPage';
import UnauthorizedPage from '../pages/unauthorized';

/**
 * Router général pour la navigation
 * @return {JSX.Element} Router
 * @constructor
 */
export default function PrivateRouter() {
  const theme = createTheme(
      {
        palette: {
          primary: {main: '#151515'},
        },
      },
      frFR,
      coreFR,
  );

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <LangContextProvider>
        <Routes>
          <Route path="" element={<Navigate to="events" replace/>} />
          <Route path="unauthorized" element={<UnauthorizedPage />} />
          <Route path="profile" element={<EditProfilePage />} />
          <Route path="search" element={<SearchPage />} />
          <Route path="events">
            <Route path="" element={<EventsListPage />} />
            <Route path=":id/details" element={<EventDetailPage />} />
            <Route path="create" element={<EventCreationPage/>}/>
            <Route path=":id/edit" element={<EventModificationPage/>}/>
            <Route path=":id/forms" element={<ResponsesListPage/>} />
          </Route>
          <Route path="users">
            <Route path="" element={<UsersPage />} />
            <Route path="create" element={<CreateUser/>} />
            <Route path=":id/details" element={<DetailsUser/>} />
            <Route path=":id/edit" element={<EditUser/>}/>
          </Route>
          <Route path="transports">
            <Route path="" element={<TransportsPage />} />
            <Route path="create" element={<CreateTransportPage/>} />
            <Route path=":id/edit" element={<EditTransportPage/>} />
            <Route path=":id/details" element={<DetailTransportPage/>} />
          </Route>
          <Route path="public">
            <Route path="events/:id/qr" element={
              <QRCodePage title="Code QR"/>
            } />
            <Route path="events/:id/forms/create"
              element={<ResponseFormPage/>} />
            <Route path="events/:id/forms/thank-you"
              element={<ThanksPage/>} />
          </Route>
        </Routes>
      </LangContextProvider>
    </ThemeProvider>
  );
}
