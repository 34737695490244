import React, {useEffect, useState} from 'react';
import searchService from '../services/search';
import {useSearchParams} from 'react-router-dom';
import SearchBox from '../components/SearchBox';
import {Container, Typography} from '@mui/material';

/**
 * Page de la recherche
 * @return {JSX.Element}
 */
function SearchPage() {
  const [results, setResults] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    searchService.search(searchParams.get('keywords')).then((res) => {
      setResults(res.data);
    });
  }, [searchParams]);

  return (
    <Container>
      <Typography variant={'h1'} marginBottom={3}>
        Résultat de recherche
      </Typography>
      { results.length > 0 ?
      results.map((result, i) => <SearchBox result={result} key={i} />) :
      <Typography variant={'subtitle1'}>Aucun résultat.</Typography>
      }
    </Container>
  );
}

export default SearchPage;
