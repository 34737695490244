import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Container, FormControl,
  Grid, MenuItem, Paper, Select,
  TextField, Typography, Box, Stack} from '@mui/material';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TransportToggleButton
  from '../components/TransportToggleButton';
import ToggleLanguageBar
  from '../components/ToggleLanguageBar';
import NoEventMessage from '../components/NoEventMessage';
import useResponseForm from '../hooks/useResponseForm';
import AlertMessage from '../../events/components/AlertMessage';
import {LangContext} from '../../global/contexts/LangContext';
import {AuthContext} from '../../auth/contexts/AuthContext';

/**
 * Formulaire qui sera rempli par les participants des évènements
 * @return {JSX.Element}
 */
function ResponseFormPage() {
  const {getRes, getCurrentLang} = useContext(LangContext);
  const {setDisplayNavbar} = useContext(AuthContext);
  const {
    eventInfo,
    response, setResponse,
    hasNoEvent,
    alert,
    transportType,
    onHandleSubmit,
    onHandleClickTransportType,
    onHandleSelectTransport,
    getSubTransportList,
  } = useResponseForm();

  /**
   * Crée les Menu Items
   * @return {unknown[]}
   */
  const menuItems = (() => {
    const list = getSubTransportList();
    return list ? list.map((transport, index) => (
      <MenuItem
        value={transport.name[getCurrentLang().toLowerCase()]}
        key={index}>
        {transport.name[getCurrentLang().toLowerCase()]}
      </MenuItem>
    )) : null;
  })();
  const [selectedMenuItem, setSelectedMenuItem] = useState('');

  const image = eventInfo.imgSource != null || eventInfo.imgSource != '' ?
   <img src={eventInfo.imgSource} alt={'Image de l\'évènement'}
     width="100%" /> : null;

  const WALK = getRes('transport_type_1');
  const CAR = getRes('transport_type_2');
  const TRANSIT = getRes('transport_type_3');
  const BIKE = getRes('transport_type_4');
  const AIR = getRes('transport_type_5');

  const nextRef = useRef(null);
  useEffect(()=>{
    setTimeout(()=> nextRef.current?.scrollIntoView({behavior: 'smooth'}),
        100);
  }, [transportType]);

  useEffect(()=>{
    setDisplayNavbar(false);
  }, []);

  if (hasNoEvent) {
    return <NoEventMessage/>;
  }

  return (
    <Container disableGutters={true} className="page-background">
      <ToggleLanguageBar/>
      <Grid container justifyContent="space-between"
        spacing={2} sx={{'padding': '1rem'}}>
        <Grid item xs={12} md={5}>
          <Paper elevation={0} sx={{'padding': '1rem'}}>
            <Grid container justifyContent="center" alignItems="center"
              spacing={1}>
              <Grid item xs={6}>
                {image}
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h3'}>
                  {eventInfo.name}
                </Typography>
                <Typography variant={'h5'}>
                  {eventInfo.date}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

        </Grid>
        <Grid item xs={12} md={7}>
          <Paper elevation={0} sx={{'padding': '1rem'}}>
            <form onSubmit={onHandleSubmit}>
              <Box>
                <Typography variant={'h3'} id='startTitle' marginBottom={1}>
                  {getRes('start_title')}
                </Typography>
                <TextField
                  id='startPoint'
                  data-testid='start-point'
                  name='startPoint'
                  size='medium'
                  placeholder={getRes('address_placeholder')}
                  variant='outlined'
                  type='text'
                  onChange={(event) => setResponse((prev) => {
                    return {...prev, start_point: event.target.value};
                  })}
                  value={response.start_point}
                />
              </Box>
              <Box marginTop={2}>
                <Typography variant={'h3'} id='transport_title' >
                  {getRes('transport_title')}
                </Typography>
                <Grid container direction='row' marginTop={1}
                  justifyContent='space-evenly' alignItems='center' >
                  <Grid item xs={4} sm={2} >
                    <TransportToggleButton
                      transportType={WALK}
                      selected={transportType === WALK}
                      handleClick={()=>onHandleClickTransportType(WALK)}
                      Icon={DirectionsWalkIcon}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} >
                    <TransportToggleButton
                      transportType={CAR}
                      selected={transportType === CAR}
                      handleClick={()=> onHandleClickTransportType(CAR)}
                      Icon={DirectionsCarIcon}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} >
                    <TransportToggleButton
                      transportType={TRANSIT}
                      selected={transportType === TRANSIT}
                      handleClick={()=> onHandleClickTransportType(TRANSIT)}
                      Icon={DirectionsBusIcon}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} >
                    <TransportToggleButton
                      transportType={BIKE}
                      selected={transportType === BIKE}
                      handleClick={()=>onHandleClickTransportType(BIKE)}
                      Icon={DirectionsBikeIcon}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} >
                    <TransportToggleButton
                      transportType={AIR}
                      selected={transportType === AIR}
                      handleClick={()=> onHandleClickTransportType(AIR)}
                      Icon={AirplanemodeActiveIcon}
                    />
                  </Grid>
                </Grid>
              </Box>
              { menuItems === null ? null : (
              <Box marginTop={3}>
                <Typography variant="h3" marginBottom={1}>
                  {getRes('specific_transport')}
                </Typography>
                <FormControl className='full-width'>
                  <Select
                    id='selectedTransportType'
                    name='selectedTransportType'
                    value={selectedMenuItem}
                    onChange={(event)=>{
                      setSelectedMenuItem(event.target.value);
                      onHandleSelectTransport(event.target.value);
                    }}
                    className="orangeField"
                    inputProps={{'data-testid': 'TransportTypeSelector'}}
                  >
                    {menuItems}
                  </Select>
                </FormControl>
              </Box>
                )}
              {transportType !== CAR ? null : (
              <Box marginTop={2}>
                <Typography variant="h3" id='username' >
                  {getRes('number_passengers')}
                </Typography>
                <Stack direction="row" justifyContent="center"
                  alignItems="center" spacing={2} >
                  <Button
                    aria-label={'Retirer un passager'}
                    onClick={() => setResponse((prev)=>{
                      return {...prev,
                        passengers_number: prev.passengers_number-1,
                      };
                    }) }
                    disabled = {response.passengers_number<=1}
                    size={'small'}
                    sx={{'border': '2px solid #ed6b39', 'height': '60px'}}
                  >
                    <RemoveIcon sx={{'color': '#000'}}/>
                  </Button>
                  <TextField id='passengers' name='passengers'
                    inputProps={{
                      'data-testid': 'passengers',
                      'style': {textAlign: 'center'},
                    }}
                    size='small'
                    required
                    variant='outlined'
                    type='text'
                    disabled
                    onChange={(event)=>setResponse((prev)=>{
                      return {...prev,
                        passengers_number: event.target.value,
                      };
                    })}
                    value={response.passengers_number}
                    sx={{'width': '45px', 'height': '45px'}}
                  />
                  <Button
                    aria-label={getRes('add_passenger')}
                    onClick={() => setResponse((prev)=>{
                      return {...prev,
                        passengers_number: prev.passengers_number+1,
                      };
                    }) }
                    disabled = {response.passengers_number>=99}
                    size={'small'}
                    sx={{'border': '2px solid #ed6b39', 'height': '60px'}}
                  >
                    <AddIcon sx={{'color': '#000'}}/>
                  </Button>
                </Stack>
              </Box>
                )}
              <Box marginTop={2}>
                <AlertMessage alert={alert}/>
                <Button ref={nextRef}
                  disableElevation type='submit' data-testid={'submit'}
                  variant='contained'
                  sx={{'width': '100%', 'height': '4rem', 'marginTop': '1rem'}}
                >
                  {getRes('submit')}
                </Button>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ResponseFormPage;
