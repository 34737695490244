import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import propTypes from 'prop-types';
import './ModalConfirm.scss';
/**
 * Le component modal de confirmation
 * @return {JSX.Element} le modal
 */
export default function ModalConfirm({
  handleClose,
  open,
  title,
  actionConfirm,
  children,
  disableConfirmButton = false,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'fit-content',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        textAlign: 'center',
        p: 4}}>
        <Typography id="modal-modal-title" variant="h6">
          {title}
        </Typography>
        {children}
        <div className='modal-buttons-container'>
          <Button variant='contained' disabled={disableConfirmButton}
            size="large" onClick={actionConfirm}>
            Confirmer
          </Button>
          <Button size="large" variant='contained' onClick={handleClose}>
            Annuler
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

ModalConfirm.propTypes = {
  handleClose: propTypes.func,
  open: propTypes.bool,
  title: propTypes.string,
  actionConfirm: propTypes.func,
  children: propTypes.object,
  disableConfirmButton: propTypes.bool,
};
