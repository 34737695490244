import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import useLoading from '../../global/components/loader/useLoading';
import responsesService from '../services/responses';

/**
 * Costum hook for reponses list operation
 * @return {object}
 */
function useResponsesList() {
  const [responsesList, setResponsesList] = useState([]);
  const {id} = useParams();
  const {setIsLoading} = useLoading();

  /**
   * Fonction qui permet de flagger et déflagger un formulaire
   * @param {Number} formID Le ID du form à changer
   */
  function onFlag(formID) {
    setIsLoading(true);
    responsesService.flagResponse(formID, id).then(() => {
      reloadList();
    }).finally(() => {
      setIsLoading(false);
    });
  }

  /**
   * Fonction pour supprimer un évènement
   * @param {string} responseId L'identifiant de la réponse.
   */
  function onDelete(responseId) {
    setIsLoading(true);
    responsesService.deleteResponse(id, responseId).then(() => {
      reloadList();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  /**
   * Supprimer tous les formulaires sélectionnés
   * @param {array} ids L'identifiant des réponses.
   */
  function onDeleteMany(ids) {
    setIsLoading(true);
    responsesService.deleteManyResponses(id, ids).then(() => {
      reloadList();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  /**
   * Mettre à jour un formulaire
   * @param {string} responseId L'identifiant de la réponse.
   * @param {object} newResponse Le contenu modifié.
   */
  function onEdit(responseId, newResponse) {
    setIsLoading(true);
    responsesService.editResponse(id, responseId, newResponse).then(() => {
      reloadList();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  /**
   * recharger la liste
   * @param {number} id
   */
  function reloadList() {
    setIsLoading(true);
    responsesService.getAllResponses(id).then((res) => {
      setResponsesList(res.data);
    }).finally(()=>{
      setIsLoading(false);
    });
  }

  useEffect(() => {
    reloadList();
  }, [id]);

  return {onFlag, onDelete, onEdit, onDeleteMany, reloadList, responsesList};
}
export default useResponsesList;
