import React, {createContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import jwt from 'jwt-decode';
import authService from '../services/auth';
import {
  setupAuthRedirector,
  addTokenToDefaultHeaders,
  removeTokenFromDefaultHeaders, expire,
  addInterceptor,
  removeInterceptors,
} from '../../axios.config';
import axios from 'axios';
import {useNavigate, useLocation} from 'react-router-dom';
import usersService from '../../users/services/users';
import useLoading from '../../global/components/loader/useLoading';

export const AuthContext = createContext(undefined);

/**
 * Contexte d'authentification
 * @param {*} children
 * @return {JSX.Element}
 */
export default function AuthContextProvider({children}) {
  const {setIsLoading} = useLoading();
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [tokenCount, setTokenCount] = useState(0);
  const [displayNavbar, setDisplayNavbar] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (!path.includes('public') && path !== '/') {
      addInterceptor();
      setupAuthRedirector(navigate, () => {
        removeInterceptors();
        removeTokenFromDefaultHeaders();
        setUser({});
        setIsLoggedIn(false);
      });
    }
  }, [location]);

  useEffect(() => {
    updateTokenCount();
  }, [user]);

  /**
   * Met à jour le nombre de tokens de l'utilisateur
   */
  const updateTokenCount = () => {
    if (user.id !== undefined) {
      usersService.getUser(user.id).then((res) => {
        // eslint-disable-next-line camelcase
        const {remaining_calls} = res.data;
        setTokenCount(remaining_calls);
      });
    } else {
      setTokenCount(0);
    }
  };

  /**
   * Retourne le nombre de jetons de l'utilisateur
   * @return {number} Le nombre de tokens
   */
  const getTokenCount = () => {
    return tokenCount;
  };

  /**
   * Fonction qui connectera un usager avec ses informations
   * @param {Object} res La réponse
   */
  const setLoginCredentials = (res) => {
    addTokenToDefaultHeaders(res.data.token);
    decodeUser(res.data.token);
    setIsLoggedIn(true);
  };

  /**
   *
   * @param {*} token
   */
  function decodeUser(token) {
    const userDecoded = jwt(token);
    expire.time = userDecoded.exp * 1000;
    setUser({
      name: userDecoded.name,
      role: userDecoded.role_id,
      id: userDecoded.id,
      level: userDecoded.level,
    });
  }

  /**
   * Teste si le refresh token est toujours valide
   */
  async function tryLogin() {
    const res = await axios.get('/auth/accessToken');
    return res;
  }

  /**
   * Fonction qui déconnecte l'utilisateur et reset le token
   */
  const handleLogout = () => {
    authService.logOut().then(() =>{
      removeInterceptors();
      removeTokenFromDefaultHeaders();
      setUser({});
      setIsLoggedIn(false);
    });
  };

  useEffect(() => {
    if (!location.pathname.includes('public') && !loggedIn) {
      setIsLoading(true);
      tryLogin().then((res) => {
        setLoginCredentials(res);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [location.pathname]);

  return (
    <AuthContext.Provider value={{
      loggedIn,
      setIsLoggedIn,
      user,
      setLoginCredentials,
      handleLogout,
      displayNavbar,
      setDisplayNavbar,
      tryLogin,
      tokenCount,
      getTokenCount,
      updateTokenCount,
    }}>
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.any,
};
