import {useContext, useEffect, useState} from 'react';
import usersService from '../../users/services/users';
import {useFormik} from 'formik';
import {dateValidationEmpty, dateValidationMoment} from '../utils/util';
import validationContants from '../../ressources/validationConstants';
import {AuthContext} from '../../auth/contexts/AuthContext';

/**
 * CUSTOM HOOK
 *
 *
 * @param {Function} submitAction
 * @param {object} initialValue
 * @param {string} mode typeOfAction
 * permet de faire changer le button submit dynamiquement
 * @return {object} retourne tout ce qu'on a besoin
 */
function useEventForm(
    submitAction,
    initialValue) {
  const {user} = useContext(AuthContext);
  const [promoters, setPromoters] = useState([]);

  useEffect( () => {
    if (user.level <= 2) {
      usersService.getPromoters().then((value) => {
        setPromoters(value);
      }).catch((err) => {
        console.log(err);
      });
    } else {
      setPromoters([user]);
    }
  }, [] );

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitAction(values, values.recalculate);
    },
    validate: (values) => {
      const errors = {};

      if (values.eventName === '') {
        errors.eventName =
                    'Le nom de l\'événenement n\'est pas présent';
      } else if (values.eventName.length > validationContants.LIMIT_DEFAULT) {
        errors.eventName = 'Le nom de l\'évènement peut contenir ' +
        `au maximum ${validationContants.LIMIT_DEFAULT} caractères.`;
      }
      if (!dateValidationEmpty(values.rawEventDateStart,
          values.eventDateStart)) {
        errors.rawEventDateStart =
                    'La date de début de l\'événement n\'est pas présent';
      } else if (!dateValidationMoment(values.rawEventDateStart,
          values.eventDateStart)) {
        errors.rawEventDateStart =
                    'La date de début l\'événement n\'est pas valide';
      }
      if (!dateValidationEmpty(values.rawEventDateEnd, values.eventDateEnd)) {
        errors.rawEventDateEnd =
                    'La date de fin de l\'événement n\'est pas présent';
      } else if (!dateValidationMoment(values.rawEventDateEnd,
          values.eventDateEnd)) {
        errors.rawEventDateEnd =
                    'La date de fin de l\'événement n\'est pas valide';
      }
      if (values.eventAddress === '') {
        errors.eventAddress =
                    'L\'adresse de l\'événenement n\'est pas présent';
      } else if (values.eventAddress.length >
        validationContants.LIMIT_DEFAULT) {
        errors.eventAddress = 'L\'adresse de l\'évènement peut contenir ' +
        `au maximum ${validationContants.LIMIT_DEFAULT} caractères.`;
      }
      if (values?.eventThanksEng?.length >
        validationContants.LIMIT_LONG) {
        errors.eventThanksEng = 'Le mot de remerciement peut contenir ' +
        `au maximum ${validationContants.LIMIT_LONG} caractères.`;
      }
      if (values?.eventThanksFr?.length >
        validationContants.LIMIT_LONG) {
        errors.eventThanksFr = 'Le mot de remerciement peut contenir ' +
          `au maximum ${validationContants.LIMIT_LONG} caractères.`;
      }
      if (values?.eventDescriptionEng?.length >
        validationContants.LIMIT_LONG) {
        errors.eventDescriptionEng = 'La description peut contenir ' +
          `au maximum ${validationContants.LIMIT_LONG} caractères.`;
      }
      if (values?.eventDescriptionFr?.length >
        validationContants.LIMIT_LONG) {
        errors.eventDescriptionFr = 'La description peut contenir ' +
          `au maximum ${validationContants.LIMIT_LONG} caractères.`;
      }
      if (values?.otherInfo?.length >
        validationContants.LIMIT_LONG) {
        errors.otherInfo = 'Les autres informations peuvent ' +
          `contenir au maximum ${validationContants.LIMIT_LONG} caractères.`;
      }

      // @TODO setAlert reçu en props ici si on veut faire comme Yan faisait
      return errors;
    },
  });

  return {formik, promoters};
}
export default useEventForm;
