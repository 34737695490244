
/**
 * Fonction qui va créer un array pour constituer le graphique
 * sur l'utilisation des différent moyen de transport
 * @param {Array} graphs Les données pour créer le graphique
 * @return {Array} Retourne un array qui contient les bonnes valeurs
 */
function transportPercentUsage(graphs) {
  return graphs.map((graph) => {
    return {
      title: graph.transport,
      value: graph.usage_percent,
      color: graph.color,
      label: graph.usage,
    };
  });
}

/**
 * Fonction qui va créer un array pour constituer le graphique
 * sur le % de GES émis par chaque véhicule
 * @param {Array} graphs Les données pour créer le graphique
 * @return {Array} Retourne un array qui contient les bonnes valeurs
 */
function transportPercentGES(graphs) {
  return graphs.map((graph) => {
    return {
      title: graph.transport,
      value: graph.ges_percent,
      color: graph.color,
      label: graph.usage,
    };
  });
}

const graphsUtils = {
  transportPercentUsage,
  transportPercentGES,
};

export default graphsUtils;
