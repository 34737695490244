import axios from 'axios';

/**
 * Permet de s'authentifier
 * @param {String} username
 * @param {String} password
 * @return {Promise} la promesse de connexion
 */
async function logIn(username, password) {
  const res = await axios.post('auth/login', {username, password});
  return res;
}


/**
 * Call d'API pour le logout
 * @param {String} token le token d'authorisation
 * @return {Promise}
 */
async function logOut() {
  return await axios.post('auth/logout');
};

const authService = {
  logIn, logOut,
};

export default authService;
