import {useContext, useState} from 'react';
import {AuthContext} from '../contexts/AuthContext.js';
import authService from '../services/auth';
import {useFormik} from 'formik';
import validationConstants from '../../ressources/validationConstants';

/**
 * Hook with the logic of the login form
 * @return {object} data and operation allowd
 */
function useLoginForm() {
  const {setLoginCredentials} = useContext(AuthContext);
  const [errorDisplay, setErrorDisplay] = useState('');
  const [canConnect, setCanConnect] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorLogin, setShowErrorLogin] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => {
      setCanConnect(false);
      authService.logIn(values.username, values.password).then((res) => {
        setCanConnect(true);
        setLoginCredentials(res);
      }).catch((error) => {
        setCanConnect(true);
        const errno = error?.response?.status;

        if (errno===400) {
          setErrorDisplay('Le nom d\'utilisateur' +
              ' et/ou le mot de passe sont invalides.');
        } else if (errno === 429) {
          setErrorDisplay('Vous avez essayé de vous connecter trop de fois,' +
              ' veuillez réessayer dans quelques minutes.');
        } else {
          setErrorDisplay('Une erreur inconnue s\'est produite, ' +
              'si cela persiste, veuillez contacter l\'administrateur.');
        }

        setShowErrorLogin(true);
      });
    },
    validate: (values) => {
      const errors = {};

      if (!values.username) {
        errors.username = 'Veuillez indiquer un nom d\'utilisateur.';
      } else if (values.username.length >
        validationConstants.LIMIT_DEFAULT) {
        errors.username = 'Le nom d\'utilisateur doit contenir ' +
        `${validationConstants.LIMIT_DEFAULT} caractères ou moins.`;
      } else if (!values.password) {
        errors.password = 'Veuillez indiquer un mot de passe.';
      } else if (values.password.length >
        validationConstants.LIMIT_PASSWORD) {
        errors.password = 'Le mot de passe peut contenir au maximum' +
        `${validationConstants.LIMIT_PASSWORD} caractères.`;
      }
      return errors;
    },
  });

  return {
    errorDisplay,
    canConnect,
    showPassword,
    setShowPassword,
    showErrorLogin,
    setShowErrorLogin,
    formik,
  };
}

export default useLoginForm;
