/**
 * objet Excel qui va contenir les listes des données
 * dans les tableaux Excel
 */
class ObjetExcel {
  /**
  * On initialise les données propres au fichier importé
  * On définit les plages des données à récupérer
  * Et on les recherches
  * 'normal' : place static
  * '' : plage dynamique
  * 'correctif' : que pour les données des formulaires
  * @param {String} nameSheet nom du sheet à traiter
  * @param {Object} dSheet les données du sheet
  */
  constructor(nameSheet, dSheet) {
    this.nameSheet = nameSheet;
    this.dataSheet = dSheet;
    this.excelDataFormatError = false;

    this.postDataEvent = [['B1', 'E1'], ['B2', 'E2']];
    this.postDataUsers = [['A5', 'D5'], ['A-', 'D-']];
    this.postTransportMode = [['F4', 'F4'], ['F-', 'F-']];

    this.dataEvent = this.research(this.postDataEvent, 'normal');
    this.dataUsers = this.research(this.postDataUsers, 'correction');
    this.transportMode = this.research(this.postTransportMode, '');
  }

  /**
  * Recherche les données en fonction des coordonnées
  * Avec le mode recherché
  * Retourne une matrice contenant les données recherchées
  * @param {array} postData array contenant les coordonnées
  * du tableau à traiter dans l'Excel
  * @param {String} modeType mode correctif, normal ou rien
  * Le normal prend strictement les cooordonnées en paramètre
  * Rien et correctif recherche s'il n'y a pas d'autres valeurs
  * à la ligne suivante du tableau
  * Correctif : prend que les données des formulaires correctements complété
  * @return {array}
  */
  research(postData, modeType) {
    const matrix = [];
    let rowSave = 0;

    /*
    * Traitement de la liste des coordonnées
    */
    postData.forEach((post) => {
      /*
      * Ils doivent avoir un début et une fin : 2 données
      */
      if (post.length == 2) {
        let row = 0;

        /*
        * On regarde si on doit chercher les informations une ligne plus bas
        * Depuis la ligne de départ (rowsave)
        */
        if (post[0][1] == '-') {
          row = rowSave;
        } else {
          row = post[0][1];
        }

        /*
        * On traite toutes les lignes de l'Excel suivantes
        */
        while (true) {
          /*
          * Initialisation des paramètres de départ
          * Numéro de colonne ...
          */
          const table = [];
          let colStart = post[0][0].charCodeAt(0);
          const colEnd = post[1][0].charCodeAt(0);
          let correct = false;
          let data = '';

          /*
          * On sauvegarde la ligne actuelle dans rowsave
          */
          if (rowSave != 0) {
            row = rowSave;
          }

          /*
          * On cherche le texte contenu dans chaque case
          * En ce déplaisant dans les colonnes
          */
          while (colStart != colEnd + 1) {
            data = '';
            if (String.fromCharCode(colStart) + row in this.dataSheet) {
              data = this.dataSheet[
                  String.fromCharCode(colStart) + row
              ]['w'];
            }

            table.push(data);

            colStart++;
          }

          /*
          * On décale le traitement à la ligne suivante
          */
          rowSave = parseInt(row) + 1;

          /*
          * En mode correctif :
          * On supprime les données incorrectes dans le tableau
          * Et on dis de garder le tableau
          * S'il contient les données obligatoires
          */

          if (modeType=='correction') {
            if (isNaN(table[1])) {
              table[1] = '';
            }
            if (isNaN(table[3])) {
              table[3] = '';
            }

            if (table[0]!='' && table[2]!='' || table[2]=='' && table[3]!='') {
              correct=true;
            }
          }

          /*
          * En fonction du mode, on traite ou pas les prochaines lignes
          * Et un tableau vide montre qu'il n'y a plus de données après
          * On arrête donc la recherche
          */
          if (table.join('')=='') {
            break;
          } else if (correct || modeType=='') {
            matrix.push(table);
          } else if (modeType == 'normal') {
            matrix.push(table);
            break;
          }
        }
      }
    });
    return matrix;
  }

  /**
  * return array
  * @return {array} dataEvent
  */
  getDataEvent() {
    return this.dataEvent;
  }

  /**
  * return array
  * @return {array} dataUsers
  */
  getDataUsers() {
    return this.dataUsers;
  }

  /**
  * return array
  * @return {array} transportMode
  */
  getTransportMode() {
    return this.transportMode;
  }

  /**
  * Met toutes les données des formulaires dans un json
  * Ne rajoute pas de données s'il n'y en à pas
  * Return array
  * @return {JSON}
  */
  getJson() {
    const json = [{}];
    let position = 0;

    this.dataUsers.forEach(function(table) {
      json[position]={
        'transport_name': table[0],
      };

      if (table[2]!='') {
        json[position]['start_point'] = table[2];
      }

      if (table[1]!='') {
        json[position]['passengers_number'] = parseInt(table[1]);
      }

      if (table[3]!='') {
        json[position]['distance'] = parseFloat(table[3]);
      }

      position++;
    });

    return json;
  }
}

export default ObjetExcel;
