import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import ModalConfirm from '../../global/modals/ModalConfirm';
import propTypes from 'prop-types';

/**
 * build a modal confirm for user suppression
 * @param {function} popup
 * {
 *  isArchived:
 *  idToArchive:
 *  openArchiveModal:
 *  action:
 * }
 * @return {jsx}
 */
function ModalConfirmArchive({popup}) {
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [eventIdToArchive, setEventIdToArchive] = useState(undefined);
  const [isArchived, setIsArchived] = useState(false);

  useEffect(()=>{
    setIsArchived(popup.isArchived);
    setEventIdToArchive(popup.idToArchive);
    setOpenArchiveModal(popup.openArchiveModal);
  }, [popup]);

  return (
    <ModalConfirm
      title={isArchived ?
          'Voulez-vous activer cet événement ?' :
          'Voulez-vous désactiver cet événement ?'
      }
      actionConfirm={()=>{
        setOpenArchiveModal(false);
        popup.action(eventIdToArchive);
      }}
      handleClose={() => {
        setOpenArchiveModal(false);
      }}
      open={openArchiveModal}>
      <Typography id="modal-modal-description" sx={{mt: 2}}>
        {isArchived ?
          'Vous pourrez toujours désactiver celui-ci à tout moment.' :
          'Vous pourrez toujours activer celui-ci à tout moment.'}
      </Typography>
    </ModalConfirm>
  );
}

ModalConfirmArchive.propTypes = {
  popup: propTypes.object,
};

export default ModalConfirmArchive;

