import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import * as PropType from 'prop-types';
import {readExcel} from '../../components/excel/importUtils/TraitementsExcel';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {useParams} from 'react-router';
import useLoading from '../../components/loader/useLoading';

/**
 * Affichage du modal
 * @param {Function} openModal permet d'ouvrir le modal
 * @param {Function} handleModalClose permet de fermer le modal
 * @param {String} fileName donne le nom du fichier
 * @param {Function} setFileName permet de set le nom du fichier
 * affiché sur le bouton import
 * @param {Function} setActualFile permet de set le fichier utilisé
 * lors du traitement
 * @param {String} actualFile donne le fichier actuel
 * @param {Function} reloadList recharge la liste des formulaires
 * @return {JSX.Element}
 */
function ModalImportExcel( {
  openModal,
  handleModalClose,
  fileName,
  setFileName,
  setActualFile,
  actualFile,
  reloadList,
}) {
  /*
   * State pour actualiser l'alert (état, message, affichage)
   */
  const [messageAlert, setMessageAlert] = useState('');
  const [alertState, setAlertState] = useState('success');
  const [openAlert, setOpenAlert] = useState(false);

  const {id} = useParams();
  const {setIsLoading} = useLoading();

  /**
  * Cette fonction est appellé quand l'utilisateur choisit un fichier
  * Elle permet de vérifier l'extension, d'afficher une snackbar en cas d'erreur
  * D'actualiser la variable globale et actualiser l'affichage du nom du fichier
  * Côté user
  * @param {String} file fichier Excel
  **/
  const bufferFile = (file) => {
    setOpenAlert(false);
    const extentionList = ['xls', 'xlsx'];
    const nameFile = file.name.split('\.');

    if (extentionList.includes(nameFile[1])) {
      setFileName(nameFile[0]);
      setActualFile(file);
    } else {
      handleAlertClick('Mauvais type de fichier.', 'error');
    }
  };

  /*
   * Affiche la snackbar alert en fonction des paramètres d'entrées
   */
  const handleAlertClick = (message = '', errorType = 'error') => {
    setAlertState(errorType);
    setMessageAlert(message);
    setOpenAlert(true);
  };


  /*
  * Cette fonction est appellé quand l'utilisateur valide
  * Elle appelle la fonction de traitement et ferme le modal et la snackbar
  * Affiche une snackbar d'erreur si l'utilisateur n'a pas sélectionné
  * Un fichier Excel
  * Recharge la page affichant la liste des formulaires de l'event
  */
  const actionReadExcel = () => {
    if (actualFile != null) {
      setIsLoading(true);
      readExcel(actualFile, id)
          .then((result) => {
            if (result) {
              handleModalClose();
              reloadList();
            } else {
              handleAlertClick('Fichier Excel incorrect.', 'warning');
              setActualFile(null);
              setFileName('Choisir votre fichier');
            }
          })
          .catch((error) => {
            console.log('actionReadExcel', error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
    } else {
      handleAlertClick('Aucun fichier sélectionné.', 'error');
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid='modal-import-excel-test'
    >
      <Box className="styleModal" data-testid="BoxModalTest">
        <div className="modal">
          <Typography id="modal-modal-title" variant="h3"
            data-testid='title-modal-test'>
            Importer un fichier Excel
          </Typography>
          <div className="import">
            <div className='upload-btn-wrapper' >
              <label htmlFor="contained-button-file">
                <Input inputProps={{accept: '.xlsx, .xls'}}
                  id="contained-button-file"
                  type="file"
                  data-testid="input-modal-test"
                  onChange={(e) => {
                    const file = e.currentTarget.files[0];
                    bufferFile(file);
                  }}
                />
                <Stack spacing={2}>
                  {openAlert ?
                    <MuiAlert onClose={() => setOpenAlert(false)}
                      severity={alertState}
                      elevation={6}
                      variant="filled">
                      {messageAlert}
                    </MuiAlert> : null }
                  <Button variant="contained"
                    component="span"
                    data-testid="button-input-modal-test"
                    className='btn'>
                    {fileName}
                  </Button>
                </Stack>
              </label>
            </div>
          </div>
          <Stack spacing={2} direction="row">
            <Button variant="contained"
              data-testid="button-validate-test"
              size="large"
              onClick={actionReadExcel}>
              Confirmer
            </Button>
            <Button data-testid="button-cancel-test"
              size="large" variant="contained"
              onClick={() => {
                setOpenAlert(false);
                handleModalClose();
              }}>
              Annuler
            </Button>
          </Stack>

        </div>
      </Box>
    </Modal>

  );
}

ModalImportExcel.propTypes = {
  openModal: PropType.bool,
  handleModalClose: PropType.func,
  fileName: PropType.string,
  setFileName: PropType.func,
  setActualFile: PropType.func,
  actualFile: PropType.object,
  setOpenAlert: PropType.func,
  setMessageAlert: PropType.func,
  setAlertState: PropType.func,
  reloadList: PropType.func,
};

export default ModalImportExcel;
