import React from 'react';
import propTypes from 'prop-types';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventIcon from '@mui/icons-material/Event';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import {Link} from 'react-router-dom';
import {Divider, Grid, Stack, Typography} from '@mui/material';

/**
 * Page de la recherche
 * @return {JSX.Element}
 */
function SearchBox({result}) {
  const iconeJsx = (type) => {
    switch (type) {
      case 'users':
        return <AccountCircleIcon fontSize="large"
          sx={{color: '#4D4D4D'}} data-testid="logoUser"/>;
      case 'events':
        return <EventIcon fontSize="large"
          sx={{color: '#4D4D4D'}} data-testid="logoEvent" />;
      default:
        return <EmojiTransportationIcon fontSize="large"
          sx={{color: '#4D4D4D'}} data-testid="logoTransport" />;
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="center" marginY={1}
        alignItems="center" spacing={2} data-testid="search-box" >
        {iconeJsx(result.dataType) }
        <Grid container justifyContent="space-between" textAlign="left">
          <Grid item xs={12} sm={6}>
            <Link to={'../' + result.dataType + '/' + result.id + '/details'}
              className="info-link" >
              { process.env.REACT_APP_URL + result.dataType + '/' +
         result.id + '/details'}
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body2'}>{result.text}</Typography>
          </Grid>
        </Grid>
      </Stack>
      <Divider/>
    </>
  );
}

export default SearchBox;

SearchBox.propTypes = {
  result: propTypes.object,
};
