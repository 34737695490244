import {Button, TextField, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import propTypes from 'prop-types';
import {AuthContext} from '../../auth/contexts/AuthContext';
import {useFormik} from 'formik';
import {useParams} from 'react-router';
import ModalConfirm from '../../global/modals/ModalConfirm';
import responsesService from '../services/responses';
import useLoading from '../../global/components/loader/useLoading';
/**
 * build a modal confirm for user suppression
 * @param {function} action
 * @return {object}
 */
function ModalModifyResponse({popup}) {
  const {user, tokenCount, updateTokenCount} = useContext(AuthContext);
  const {id} = useParams();
  const [openModal, setOpenModal] = useState(popup.open);
  const [responseToModify, setResponseToModify] = useState(popup.data);
  const {setIsLoading} = useLoading();

  useEffect(()=>{
    if (popup.data) {
      formik.values.adress =
      popup.data.start_point ? popup.data.start_point : '';
      formik.values.distance =
      popup.data.distance ? popup.data.distance : 0;
      formik.values.flag = popup.data.is_valid;
    }
    setResponseToModify(popup.data);
    setOpenModal(popup.open);
  }, [popup]);

  /**
   * Recalculate the distance with google
   */
  function recalcWithGoogle() {
    setIsLoading(true);
    const data = {
      start_point: formik.values.adress,
      transport_id: responseToModify.transport.id,
    };

    responsesService.distanceBetween(id, data).then((res) => {
      formik.setFieldValue('distance', res.data.distance);
      updateTokenCount();
    }).finally(()=> setIsLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      adress: '',
      distance: '',
      flag: false,
    },
    onSubmit: (values) => {
      const newForm = {
        start_point: values.adress,
        distance: parseFloat(values.distance),
        transport_id: Number(responseToModify.transport.id),
        passengers_number: responseToModify.passengers_number,
        is_valid: values.flag,
      };
      setOpenModal(false);
      popup.action(responseToModify.id, newForm);
    },
    validate: (values) => {
      const errors = {};
      if (!values.distance) {
        errors.distance = 'Ce champ est obligatoire.';
      }

      if (values.distance < 0 || values.distance == 0) {
        errors.distance = 'La distance doit être positive.';
      }

      return errors;
    },
  });

  const jss = {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#ed6b39',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ed6b39',
      },
    },
  };

  return (
    <ModalConfirm title='Modifier une donnée de sondage'
      actionConfirm={formik.handleSubmit}
      handleClose={() => setOpenModal(false)}
      open={openModal}
      disableConfirmButton={formik.values.adress === ''}>
      <form noValidate>
        <div className="inputs-container">
          <span className="input-label">
              Adresse de départ
          </span>
          <div className='box'>
            <TextField
              className='input-edit'
              id="adress"
              name="adress"
              inputProps={{'data-testid': 'adress'}}
              size="small"
              required
              error={formik.touched.adress && Boolean(formik.errors.adress)}
              variant="outlined"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.adress}
              sx={jss}
            />
          </div>
        </div>
        <div className="inputs-container">
          <span className="input-label">
              Distance parcourue (km)
          </span>
          <div className="box">
            <TextField
              className='input-edit'
              id="distance"
              name="distance"
              inputProps={{'data-testid': 'distance'}}
              size="small"
              required
              variant="outlined"
              type="text"
              error={ formik.touched.distance &&
                  Boolean(formik.errors.distance)
              }
              onChange={formik.handleChange}
              value={formik.values.distance}
              sx={jss}
            />
            <div className={'box-right'}>
              <Button
                type="button"
                variant="contained"
                size='small'
                onClick={recalcWithGoogle}
                disabled={formik.values.adress === '' ||
                        (user.level === 3 && tokenCount === 0)}
                sx={{
                  'width': 'fitContent',
                  'textTransform': 'none',
                  'backgroundColor': '#ed6b39',
                  '&:hover': {
                    backgroundColor: '#bc4c20',
                    boxShadow: 'none',
                  },
                  '&:disabled': {
                    backgroundColor: '#d9d9d9',
                    color: '#4d4d4d',
                    boxShadow: 'none',
                  },
                }}
              >
                  Recalcul avec Google
              </Button>
            </div>
          </div>
          {(user.level === 3 && tokenCount === 0) ?
              <div className='error-text'>
                <Typography
                  color={'#FF0000'}
                  sx={{textAlign: 'center'}}
                >
                  Vous n&apos;avez plus de jetons.
                  Merci de contacter le CQEER pour régler la situation.
                </Typography>
              </div> : null }
          {formik.touched.distance && Boolean(formik.errors.distance) ? (
              <div className="form-errors">{formik.errors.distance}</div>
            ) : null}
        </div>
      </form>
    </ModalConfirm>
  );
}

ModalModifyResponse.propTypes = {
  popup: propTypes.object,
};

export default ModalModifyResponse;
