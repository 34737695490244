import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import ModalConfirm from '../../global/modals/ModalConfirm';


/**
 * build a modal confirm for user suppression
 * @param {object} popup
 * {
 *   idsToDelete:
 *   openDeleteManyModal:
 *   action:
 * }
 * @return {object}
 */
export function ModalConfirmDeleteMany({popup}) {
  const [openDeleteManyModal, setOpenDeleteManyModal] = useState(false);
  const [deleteCheckBoxValue, setDeleteCheckBoxValue] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState(undefined);

  useEffect(()=>{
    setIdsToDelete(popup.idsToDelete);
    setOpenDeleteManyModal(popup.openDeleteManyModal);
  }, [popup]);

  return (
    <ModalConfirm
      title="Voulez-vous supprimer ces événements ?"
      actionConfirm={() => {
        popup.action(idsToDelete);
        setDeleteCheckBoxValue(false);
        setOpenDeleteManyModal(false);
      }
      }
      handleClose={() => {
        setDeleteCheckBoxValue(false);
        setOpenDeleteManyModal(false);
      }}
      open={openDeleteManyModal}
      disableConfirmButton={!deleteCheckBoxValue}
    >
      <FormGroup sx={{mt: 2}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={deleteCheckBoxValue}
              onChange={
                () => setDeleteCheckBoxValue((prevValue) =>!prevValue)
              }
              sx={{
                'color': '#ED6B39',
                '&.Mui-checked': {color: '#ED6B39'},
              }}
            />
          }
          label="Je suis conscient que cette action est irréversible."
        />
      </FormGroup>
    </ModalConfirm>
  );
}
ModalConfirmDeleteMany.propTypes = {
  popup: propTypes.object,
};

export default ModalConfirmDeleteMany;
