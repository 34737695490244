import axios from 'axios';
import VehicleGoogleMock from '../../global/mock/vehiclesGoogle.json';

/**
 * Récupère les différents véhicules enregistrés
 * @return {JSON} Liste des véhicules
 */
async function getAllTransports() {
  const res = await axios.get('transports');
  return res;
}

/**
 * Récupère les différents véhicules enregistrés
 * @param {number} id Le id du véhicule
 * @return {JSON} Liste des véhicules
 */
async function getTransport(id) {
  return await axios.get(`transports/${id}`);
}

/**
 * Permet de lancer l'appel de création d'un véhicule
 * @param {*} transport Un objet représentant le nouveau véhicule
 * @return {*} Un objet représentant le nouveau véhicule
 */
async function sendCreateTransport(transport) {
  const res = await axios.post('/transports', transport);
  return res;
}

/**
 * Permet de lancer l'appel de modification d'un véhicule
 * @param {Number} id L'id du véhicule modifié
 * @param {*} transport Un objet représentant le véhicule modifié
 * @return {*} Le message renvoyé par l'API
 */
async function sendEditTransport(id, transport) {
  const res = await axios.put('/transports/' + id, transport);
  return res;
}

/**
 * Récupère les véhicules de correspondance de Google
 * @return {*} Liste des véhicules de correspondance
 */
async function getVehicleGoogleEnum() {
  /* const res = await axios.get('transports/google');
  return res.data.data; */
  return {'vehicle_google_enumeration': VehicleGoogleMock};
}
/**
 * Fonction qui delete un véhicule dans l'API
 * @param {Number} id Le id du véhicule
 * @return {*} Réponse du call
 */
async function deleteTransport(id) {
  return await axios.delete(`/transports/${id}`);
}

/**
 * Récupère les différentes catégories de véhicule
 * @return {Array} Liste des catégories de véhicule
 */
function getAllTransportTypes() {
  return [
    {
      'id': 1,
      'name_fr': 'Marche',
      'name_en': 'Walking',
      'icon_name': 'DirectionsWalkIcon',
    },
    {
      'id': 2,
      'name_fr': 'Véhicule',
      'name_en': 'Vehicle',
      'icon_name': 'DirectionsCarIcon',
    },
    {
      'id': 3,
      'name_fr': 'Transit',
      'name_en': 'Transit',
      'icon_name': 'DirectionsBusIcon',
    },
    {
      'id': 4,
      'name_fr': 'Vélo',
      'name_en': 'Biking',
      'icon_name': 'DirectionsBikeIcon',
    },
    {
      'id': 5,
      'name_fr': 'Aérien',
      'name_en': 'Air Travel',
      'icon_name': 'AirplanemodeActiveIcon',
    },
  ];
}

const transportsService = {
  getAllTransports,
  getTransport,
  sendCreateTransport,
  getVehicleGoogleEnum,
  getAllTransportTypes,
  sendEditTransport,
  deleteTransport,
};

export default transportsService;
