import React, {useContext} from 'react';
import PrivateRouter from './global/router/PrivateRouter';
import PublicRouter from './global/router/PublicRouter';
import {AuthContext} from './auth/contexts/AuthContext';
import Footer from './global/components/footer';
import {CssBaseline} from '@mui/material';
import './App.scss';
import './users/styles/users.scss';

/**
 * App component
 * Display the correct router according to authentication status
 * @return {JSX.Element}
 */
function App() {
  const {loggedIn} = useContext(AuthContext);

  return (
    <div className="app">
      <CssBaseline />
      {loggedIn ?
        (<>
          <PrivateRouter />
          <Footer />
        </>) :
        (<PublicRouter />)
      }
    </div>
  );
}

export default App;
