import {Button, Container, Grid, Stack, Typography} from '@mui/material';
import React, {useContext, useState} from 'react';
import {AuthContext} from '../../auth/contexts/AuthContext';
import propTypes from 'prop-types';
import useUserDetail from '../hooks/useUserDetail';
import ModalConfirmDelete from '../../global/modals/ModalConfirmDelete';

/**
 *
 * @return {jsx}
 */
function UserDetail() {
  const {user} = useContext(AuthContext);
  const {onEdit, onDelete, userDetail} = useUserDetail();
  const [popup, setPopup] = useState({
    title: '',
    openDeleteModal: false,
    idtoDelete: null,
    action: null,
  });

  // Si admin, voir les informations supplémentaires
  const infos = user.level <= 2 ? (
    <>
      <Typography variant="subtitle1">
        Informations supplémentaires
      </Typography>
      <Typography variant={'body1'} data-testid='userInfo'>
        {userDetail.info ? userDetail.info :
        <span className="generic-message">Aucune information disponible.</span>}
      </Typography>
    </>
  ) : null;

  // si organisateur autonome
  const tokens = userDetail.role.id === 4 ? (
    <>
      <Typography variant="subtitle1">
        Jetons restants
      </Typography>
      <Typography variant={'body1'} data-testid="tokens">
        {userDetail.remaining_calls}
      </Typography>
    </>
    ) : (null);

  const buttons = user.level <= 1 && user.id != userDetail.id ? (
    <Stack direction="row" spacing={2}>
      <Button data-testid="btnEditUser" type="button"
        variant="contained" size="small" onClick={onEdit} >
          Modifier l&apos;utilisateur
      </Button>
      <Button data-testid="btnDeleteUser" type="button"
        variant="contained" size="small" onClick={() => setPopup({
          title: 'Voulez-vous supprimer cet utilisateur ?',
          idToDelete: null,
          openDeleteModal: true,
          action: onDelete,
        }) } >
          Supprimer l&apos;utilisateur
      </Button>
    </Stack>) : null;

  return (
    <Container>
      <Grid container direction="row" spacing={2} mb={5}
        justifyContent="space-between" textAlign="center">
        <Grid item md={6}>
          <Typography variant={'h1'} data-testid='transportName'>
            {userDetail.name}
          </Typography>
        </Grid>
        <Grid item md={6}>
          {buttons}
        </Grid>
      </Grid>

      <Typography variant="subtitle1">
        Identifiant
      </Typography>
      <Typography variant={'body1'} data-testid="userLogin">
        {userDetail.login}
      </Typography>


      <Typography variant="subtitle1">
        Rôle
      </Typography>
      <Typography variant={'body1'} data-testid="userRole">
        {userDetail.role.name}
      </Typography>

      {tokens}

      {infos}

      <ModalConfirmDelete popup={popup}/>
    </Container>
  );
}

UserDetail.propTypes = {
  onEdit: propTypes.func,
  userDetail: propTypes.object,
};

export default UserDetail;
