import React from 'react';

import ResponsesList from '../components/ResponsesList';
import '../styles/responsesListPage.scss';
/**
 * Page de la liste des formulaires
 * @return {JSX.Element} la page de la liste des formualaires
 */
function ResponsesListPage() {
  // Tout le contenu a été déplacé dans le composant ResponsesList
  // pour pouvoir recharger la liste après l'imporation du fichier Excel
  return (
    <ResponsesList/>
  );
}

export default ResponsesListPage;

