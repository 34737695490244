import React, {useContext} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  TextField,
  Typography} from '@mui/material';
import {DesktopDatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDate from '@mui/lab/AdapterMoment';
import Moment from 'moment';
import propTypes from 'prop-types';
import {AuthContext} from '../../auth/contexts/AuthContext';
import NotEnoughTokensWarning from './NotEnoughTokensWarning';
import '../styles/eventForm.scss';

/**
 * s'agit de la composante qui fait l'affichage du form
 * Gestion de l'upload d'image avec multer
 * https://www.npmjs.com/package/multer
 * @return {JSX.Element} le jsx configuré pour le from
 */
export function EventForm({
  formik, promoters, submitText, mode, oldEventAddress,
}) {
  const {user} = useContext(AuthContext);

  return (
    <form className="form-group"
      onSubmit={formik.handleSubmit} data-testid="eventForm">
      <Box className="container-input">
        <Typography variant="subtitle1">
            Nom de l&apos;événement
        </Typography>
        <TextField id='eventName' name='eventName'
          size='small'
          variant="outlined"
          value={formik.values.eventName}
          inputProps={{'data-testid': 'eventNameInput'}}
          onChange={formik.handleChange}
          error={formik.touched.eventName && Boolean(formik.errors.eventName)}
          helperText={formik.touched.eventName && formik.errors.eventName}
        />
      </Box>

      {/* Logo de l'evenement*/}
      <Box className="container-input">
        <Typography variant="subtitle1">
            Logo
        </Typography>
        <label className='logo-area' htmlFor='logo'>
          <input style={{display: 'none'}}
            accept="image/*"
            id='logo'
            name='logo'
            type='file'
            data-testid='eventLogo'
            onChange={((event) => {
              const file = event.currentTarget.files[0];
              formik.setFieldValue('eventLogoImageFile', file);
              formik.setFieldValue('eventLogoName', file.name);
            })} />
          <TextField
            size='small'
            disabled
            error={formik.touched.eventLogoImageFile &&
                            Boolean(formik.errors.eventLogoImageFile)}
            helperText={formik.touched.eventLogoImageFile &&
                            formik.errors.eventLogoImageFile}
            inputProps={{'data-testid': 'eventLogoInput'}}
            value={formik.values.eventLogoName}
          />
          <Button variant='contained' component='span'
            data-testid="eventLogoUploadButton"
            sx={{'ml': '0.5rem', 'minWidth': '160px'}}>
                  Choisir l&apos;image
          </Button>
        </label>
        <Typography className='warningPicture'>
            Conseil de sobriété numérique:
            utilisez une photo de poids inférieur à 80ko et/ou
            une résolution d&apos;environ 420 pixels en hauteur et largeur.
        </Typography>
      </Box>

      { /* nom de l'organisateur*/}
      <Box className="container-input">
        <Typography variant="subtitle1">
            Organisateur
        </Typography>
        <Autocomplete id='eventOrganizerName' name='eventOrganizerName'
          autoHighlight
          fullWidth
          disabled={user.level >= 3}
          onChange={(e, value) => {
            formik.setFieldValue('eventOrganizerId', value.id);
            formik.setFieldValue('eventOrganizerName', value.name);
          }}
          size='small'
          options={promoters}
          value={{
            id: formik.values.eventOrganizerId,
            name: formik.values.eventOrganizerName,
          }}
          getOptionLabel={(promoter) => promoter.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params}
            inputProps={{...params.inputProps,
              'data-testid': 'eventPromoterInput',
            }} />}
        />
      </Box>

      { /* date */}
      <Box className="container-input">
        <Typography variant="subtitle1">
            Date de l&apos;événement
        </Typography>

        <LocalizationProvider dateAdapter={AdapterDate}>
          <DesktopDatePicker
            id='rawEventDateStart'
            name='rawEventDateStart'
            value={formik.values.rawEventDateStart}
            maxDate={formik.values.rawEventDateEnd}
            onChange={
              (newValue) => {
                formik.setFieldValue('rawEventDateStart', newValue);
                const momentDate = new Moment(newValue);
                const dateTransform = momentDate.format('YYYY-MM-DD');
                formik.setFieldValue('eventDateStart', dateTransform);
              }
            }
            renderInput={(params) => <TextField
              size='small'
              {...params}
              error={formik.touched.rawEventDateStart &&
                              Boolean(formik.errors.rawEventDateStart)}
              helperText={formik.touched.rawEventDateStart &&
                              formik.errors.rawEventDateStart}
              label='Date de début'
              inputProps={
                {
                  ...params.inputProps,
                  'data-testid': 'eventDateStartInput',
                }
              }
              sx={{'width': '250px',
                'marginRight': '15px',
                'marginBottom': '15px'}}
            />}
          />
          <DesktopDatePicker
            id='rawEventDateEnd'
            name='rawEventDateEnd'
            value={formik.values.rawEventDateEnd}
            minDate={formik.values.rawEventDateStart}

            onChange={
              (newValue) => {
                formik.setFieldValue('rawEventDateEnd', newValue);
                const momentDate = new Moment(newValue);
                const dateTransform = momentDate.format('YYYY-MM-DD');
                formik.setFieldValue('eventDateEnd', dateTransform);
              }
            }
            renderInput={(params) => <TextField
              size='small'
              {...params}
              error={formik.touched.rawEventDateEnd &&
                              Boolean(formik.errors.rawEventDateEnd)}
              helperText={formik.touched.rawEventDateEnd &&
                              formik.errors.rawEventDateEnd}
              label='Date de fin'
              inputProps={
                {
                  ...params.inputProps,
                  'data-testid': 'eventDateEndInput',
                }
              }
              sx={{'width': '250px'}}
            />}
          />
        </LocalizationProvider>
      </Box>

      { /* addresse */}
      <Box className="container-input">
        <Typography variant="subtitle1">
            Adresse de l&apos;événement
        </Typography>
        <TextField
          fullWidth
          size='small'
          id='eventAddress'
          name='eventAddress'
          disabled={user.level === 4}
          placeholder='...'
          inputProps={{'data-testid': 'eventAddressInput'}}
          value={formik.values.eventAddress}
          onChange={formik.handleChange}
          error={formik.touched.eventAddress &&
                        Boolean(formik.errors.eventAddress)}
          helperText={formik.touched.eventAddress &&
                        formik.errors.eventAddress}
        />
      </Box>

      { formik.values.eventAddress !== oldEventAddress && mode==='update' ?
        <NotEnoughTokensWarning formik={formik} /> :
        null }

      { /* Description de l'événement */}
      <Box className="container-input">
        <Typography variant="subtitle1">
            Description de l&apos;événement (Français)
        </Typography>

        <TextField id='eventDescriptionFr' name='eventDescriptionFr'
          fullWidth
          multiline
          size='small'
          minRows={3}
          placeholder='...'
          inputProps={{'data-testid': 'eventDescFrInput'}}
          value={formik.values.eventDescriptionFr}
          onChange={formik.handleChange}
          error={formik.touched.eventDescriptionFr &&
                        Boolean(formik.errors.eventDescriptionFr)}
          helperText={formik.touched.eventDescriptionFr &&
                        formik.errors.eventDescriptionFr}
        />
      </Box>
      <Box className="container-input">
        <Typography variant="subtitle1">
            Description de l&apos;événement (Anglais)
        </Typography>
        <TextField id='eventDescriptionEng' name='eventDescriptionEng'
          fullWidth
          multiline
          size='small'
          minRows={3}
          placeholder='...'
          inputProps={{'data-testid': 'eventDescEngInput'}}
          value={formik.values.eventDescriptionEng}
          onChange={formik.handleChange}
          error={formik.touched.eventDescriptionEng &&
                        Boolean(formik.errors.eventDescriptionEng)}
          helperText={formik.touched.eventDescriptionEng &&
                        formik.errors.eventDescriptionEng}
        />
      </Box>

      { /* Mot de remerciement aux participants  */}
      <Box className="container-input">
        <Typography variant="subtitle1">
            Mot de remerciement aux participants (Français)
        </Typography>
        <TextField id='eventThanksFr' name='eventThanksFr'
          fullWidth
          multiline
          size='small'
          minRows={3}
          placeholder='...'
          inputProps={{'data-testid': 'eventThanksFrInput'}}
          value={formik.values.eventThanksFr}
          onChange={formik.handleChange}
          error={formik.touched.eventThanksFr &&
                        Boolean(formik.errors.eventThanksFr)}
          helperText={formik.touched.eventThanksFr &&
                        formik.errors.eventThanksFr}
        />
      </Box>
      <Box className="container-input">
        <Typography variant="subtitle1">
            Mot de remerciement aux participants (Anglais)
        </Typography>
        <TextField id='eventThanksEng' name='eventThanksEng'
          fullWidth
          multiline
          size='small'
          minRows={3}
          placeholder='...'
          inputProps={{'data-testid': 'eventThanksEngInput'}}
          value={formik.values.eventThanksEng}
          onChange={formik.handleChange}
          error={formik.touched.eventThanksEng &&
                        Boolean(formik.errors.eventThanksEng)}
          helperText={formik.touched.eventThanksEng &&
                        formik.errors.eventThanksEng}
        />
      </Box>

      { /* Photo pour le mot de remerciement */}
      <Box className="container-input">
        <Typography variant="subtitle1">
            Photo pour le mot de remerciement
        </Typography>
        <label className='thanks-photo-area' htmlFor='eventThanksImage'>
          <input style={{display: 'none'}}
            data-testid='eventThanks'
            accept="image/*"
            id='eventThanksImage'
            name='eventThanksImage'
            type='file'
            onChange={((event) => {
              const file = event.currentTarget.files[0];
              formik.setFieldValue('eventThanksImageFile', file);
              formik.setFieldValue('eventThanksImageName',
                  file.name);
            })} />
          <TextField
            disabled
            size='small'
            error={formik.touched.eventThanksImageFile &&
                          Boolean(formik.errors.eventThanksImageFile)}
            helperText={formik.touched.eventThanksImageFile &&
                          formik.errors.eventThanksImageFile}
            inputProps={{'data-testid': 'eventThanksPhotoInput'}}
            value={formik.values.eventThanksImageName}
          />
          <Button
            variant='contained'
            component='span'
            data-testid="eventThanksUploadButton"
            sx={{'ml': '0.5rem', 'minWidth': '160px'}} >
              Choisir l&apos;image
          </Button>
        </label>
        <Typography className='warningPicture'>
            Conseil de sobriété numérique:
            utilisez une photo de poids inférieur à 80ko et/ou
            une résolution d&apos;environ 420 pixels en hauteur et largeur.
        </Typography>
      </Box>

      { /* Autres Informations */}
      <Box className="container-input">
        <Typography variant="subtitle1">
            Autres Informations
        </Typography>

        <TextField id='otherInfo' name='otherInfo'
          fullWidth
          multiline
          size='small'
          minRows={3}
          placeholder='...'
          inputProps={{'data-testid': 'eventOtherInfoInput'}}
          value={formik.values.otherInfo}
          onChange={formik.handleChange}
          error={formik.touched.otherInfo &&
                        Boolean(formik.errors.otherInfo)}
          helperText={formik.touched.otherInfo &&
                        formik.errors.otherInfo}
        />
      </Box>

      { /* Nombre de participants attendus */}
      <Box className="container-input">
        <Typography variant="subtitle1">
            Nombre de participants attendus
        </Typography>
        <TextField id='numberParticipant' name='numberParticipant'
          fullWidth
          size='small'
          type='number'
          inputProps={{
            'data-testid': 'eventNbPartInput',
            'min': 0,
            'max': 2147483646,
            'maxLength': 10,
          }}
          value={formik.values.numberParticipant}
          onChange={formik.handleChange}
          error={formik.touched.numberParticipant &&
                        Boolean(formik.errors.numberParticipant)}
          helperText={formik.touched.numberParticipant &&
                        formik.errors.numberParticipant}
        />
      </Box>

      { /* Afficher le nombre de GES émis par le participant */}
      <Box className="container-input">
        <Typography variant="subtitle1">
          Afficher le nombre de GES émis par le participant
          <Checkbox
            id='showGES'
            name='showGES'
            checked={formik.values.showGES}
            onChange={formik.handleChange}
            value={formik.values.showGES}
            data-testid='eventGesCheckBoxInput'
          />
        </Typography>
      </Box>

      <Button type='submit' variant='contained'
        data-testid='eventSubmitButton'
        sx={{'marginTop': '1rem'}} >
        {submitText}
      </Button>
    </form>
  );
}

EventForm.propTypes = {
  formik: propTypes.object,
  promoters: propTypes.array,
  submitText: propTypes.string,
  mode: propTypes.string,
  oldEventAddress: propTypes.string,
};

export default EventForm;

