import React from 'react';
import {
  IconButton,
  InputAdornment,
  Button,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RoleRadioButtons from './RoleRadioButtons';
import propTypes from 'prop-types';

/**
 * Composant d'un formulaire d'un utilisateur.
 * Fonctionne pour la création et la modification d'un utilisateur.
 * @return {JSX.Element}
 */
export default function UserForm({
  submitText, showPassword, onHandleClickShowPassword,
  formik, mode, isOrganizer = false,
}) {
  const showError = (key) => {
    if (formik.touched[key] && Boolean(formik.errors[key])) {
      return (<div className="form-errors">{formik.errors[key]}</div>);
    }
  };

  const jetonJsx = !isOrganizer ? null : (
    <Box className="inputs-container">
      <Typography variant="subtitle1" id="remaining_calls">
          Jetons restants
      </Typography>
      <TextField id="remaining_calls" name="remaining_calls"
        required
        inputProps={{'min': 0, 'data-testid': 'remaining-calls'}}
        size="small"
        error={formik.touched.remaining_calls &&
                Boolean(formik.errors.remaining_calls)}
        variant="outlined"
        type="number"
        onChange={formik.handleChange}
        value={formik.values.remaining_calls}
      />
      {showError('remaining_calls')}
    </Box>
    );


  return (
    <form noValidate className="form-group" data-testid="form"
      onSubmit={formik.handleSubmit} >

      {/* Afficher les jetons du l'utilisation
      si c'est un organisateur autonome*/}
      {jetonJsx}

      {mode === 'profil' ? null : (
        <Box className="container-input">
          <Typography variant="subtitle1" id="form-radio-buttons">
            Rôle
          </Typography>
          <RoleRadioButtons formik={formik} />
          {showError('role_id')}
        </Box>
      )}

      <Box className="container-input">
        <Typography variant="subtitle1" id="login">
          Identifiant de connexion
        </Typography>
        <TextField id="login" name="login"
          disabled={mode !== 'create'}
          inputProps={{'data-testid': 'login'}}
          size="small"
          required
          error={formik.touched.login && Boolean(formik.errors.login)}
          variant="outlined"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.login}
        />
        {showError('login')}
      </Box>


      {mode === 'profil' ? null : (
        <Box className="container-input">
          <Typography variant="subtitle1" id="username">
            {formik.values.role_id === 1 || formik.values.role_id === 4 ?
              `Nom de l'organisation` :
              `Nom de l'utilisateur`}
          </Typography>
          <TextField id="name" name="name"
            inputProps={{'data-testid': 'username'}}
            size="small"
            required
            error={formik.touched.name && Boolean(formik.errors.name)}
            variant="outlined"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {showError('name')}
        </Box>
      )}

      <Box className="container-input">
        <Typography variant="subtitle1" id="password">
          {mode === 'profil' ? 'Nouveau mot de passe' : 'Mot de passe'}
        </Typography>

        <TextField id="password" name="password"
          inputProps={{'data-testid': 'password'}}
          size="small"
          required
          error={formik.touched.password && Boolean(formik.errors.password)}
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          onChange={formik.handleChange}
          values={formik.values.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={onHandleClickShowPassword}
                  data-testid="toggle-password"
                  edge="end"
                  sx={{color: '#ed6b39'}}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {showError('password')}
      </Box>

      <Box className="container-input">
        <Typography variant="subtitle1" id="confirm-password">
          Confirmer le mot de passe
        </Typography>
        <TextField id="confirm-password" name="confirmPassword"
          inputProps={{'data-testid': 'confirm-password'}}
          size="small"
          required
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          onChange={formik.handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={onHandleClickShowPassword}
                  data-testid="toggle-confirm-password"
                  edge="end"
                  sx={{color: '#ed6b39'}}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {showError('confirmPassword')}
      </Box>

      {mode === 'profil' ? null : (
        <Box className="container-input">
          <Typography variant="subtitle1">
            Informations supplémentaires
          </Typography>
          <TextField id="info" name="info"
            multiline minRows={2} maxRows={10}
            size="small"
            inputProps={{'data-testid': 'info-user'}}
            value={formik.values.info}
            onChange={formik.handleChange}
          />
          {showError('info')}
        </Box>
      )}
      <Button type="submit" variant="contained"
        data-testid="submit-button"
        sx={{'marginTop': '1rem'}} >
        {submitText}
      </Button>
    </form>
  );
}

UserForm.propTypes = {
  submitText: propTypes.string.isRequired,
  showPassword: propTypes.bool,
  onHandleClickShowPassword: propTypes.func,
  formik: propTypes.object,
  mode: propTypes.string,
  isOrganizer: propTypes.bool,
};
