import {useFormik} from 'formik';
import {useState} from 'react';
import validationConstants from '../../ressources/validationConstants';

/**
 * Composant de la page de création/modification d'un utilisateur.
 * @param {Function} submitAction fonction appelé
 * @param {JSON} initialValue ...
 * @param {boolean} mode Si on est en mode modificaion ou pas.
 *                             Retire certaines validations du formulaire
 * @return {Object} Le hook pour le formulaire
 */
export default function useUserForm(
    submitAction,
    initialValue,
    mode = '') {
  const [showPassword, setShowPassword] = useState(false);
  const onHandleClickShowPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitAction(values);
    },
    validate: (values) => {
      const errors = {};
      /* Validation du mot de passe
       * - doit contenir au moins 8 caractères
       * - doit contenir au moins une lettre minuscule
       * - doit contenir au moins une lettre majuscule
       * - doit contenir au moins un chiffre
       * - peut contenir un ou plusieurs caractères spéciaux */
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=[!"/$%?&*@#-+=]?).{8,}$/;

      if (!values.role_id) {
        values.role_id === 0;
        errors.role_id = 'Veuillez sélectionner un rôle.';
      }

      if (!values.login) {
        errors.login = 'Veuillez entrer un identifiant de connexion.';
      } else if (values.login.length > validationConstants.LIMIT_DEFAULT) {
        errors.login = 'L\'identifiant de connexion peut contenir au maximum ' +
        `${validationConstants.LIMIT_DEFAULT} caractères.`;
      }

      if (!values.name) {
        if (radioValue !== 1 && radioValue !== 4) {
          errors.name = 'Veuillez indiquer un nom d\'utilisateur.';
        } else {
          errors.name = 'Veuillez indiquer un nom d\'organisation.';
        }
      } else if (values.name.length > validationConstants.LIMIT_DEFAULT) {
        errors.name = 'Le nom peut contenir au maximum ' +
        `${validationConstants.LIMIT_DEFAULT} caractères.`;
      }

      if (!values.password) {
        // Mis dans un if imbriqué pour ne pas passer
        // au prochain else-if si le mot de passe est vide
        // et qu'on est en mode modification
        if (mode !== 'edit') {
          errors.password = 'Veuillez indiquer un mot de passe.';
        }
      } else if (values.password.length > validationConstants.LIMIT_PASSWORD) {
        errors.password = 'Le mot de passe peut contenir au maximum ' +
        `${validationConstants.LIMIT_PASSWORD} caractères.`;
      } else if (!regex.test(values.password)) {
        errors.password =
          'Le mot de passe doit contenir au moins 8 caractères' +
          ' dont une majuscule, une minuscule et un chiffre. Il peut' +
          ' également contenir un symbole.';
      } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Les mots de passe ne correspondent pas.';
      }

      if (values?.info?.length > validationConstants.LIMIT_LONG) {
        errors.info = `L'information supplémentaire peut contenir au maximum ` +
        `${validationConstants.LIMIT_LONG} caractères.`;
      }

      if (mode = 'edit') {
        if (values?.remaining_calls === undefined ||
          values?.remaining_calls.toString() === '') {
          errors.remaining_calls = 'Le nombre de jetons restants ' +
          'doit être fourni.';
        } else if (values?.remaining_calls < 0) {
          errors.remaining_calls = 'Le nombre de jetons restants ' +
          'doit être supérieur ou égal à zéro.';
        }
      }

      return errors;
    },
  });

  return {
    showPassword,
    onHandleClickShowPassword,
    formik,
    mode,
  };
}
