import React from 'react';
import UserDetail from '../components/UserDetail';

/**
 * Fonction qui retourne la page détails d'un utilisateur
 * @return {JSX.Element}
 */
export default function DetailUserPage() {
  return (
    <UserDetail/>
  );
}
