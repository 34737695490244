import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import userServices from '../services/users';
import {AuthContext} from '../../auth/contexts/AuthContext';
import propTypes from 'prop-types';


/**
 * RoleRadioButtons
 * @return {jsx}
 */
function RoleRadioButtons({formik}) {
  const {user} = useContext(AuthContext);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (user.level == 1) {
      userServices.getUserRoles().then((res) => {
        setRoles(res);
      });
    }
  }, [user]);
  return (
    <RadioGroup
      row
      aria-labelledby="add-user-label"
      onChange={formik.handleChange}
      value={formik.values.role_id}
    >
      {roles.map((role) => (
        <FormControlLabel
          key={role.id}
          value={role.id}
          control={
            <Radio
              sx={{
                'color': '#151515',
                '&.Mui-checked': {
                  color: '#151515',
                },
              }}
              inputProps={{
                'name': 'role_id',
                'data-testid': `radio-${role.id}`,
              }}
            />
          }
          label={role.name}
        />
      ))}
    </RadioGroup>
  );
}

RoleRadioButtons.propTypes = {
  formik: propTypes.object,
};
export default RoleRadioButtons;
