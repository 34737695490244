import React from 'react';
import {Route, Routes} from 'react-router';
import LangContextProvider from '../contexts/LangContext';
import ResponseFormPage from '../../responses/pages/ResponseFormPage';
import ThanksPage from '../../responses/pages/ThanksPage';
import LoginPage from '../../auth/pages/LoginPage';
import QRCodePage from '../pages/qrCode';

/**
 *
 * @return {jsx}
 */
function PublicRouter() {
  return (
    <LangContextProvider>
      <Routes>
        <Route path="*" element={<LoginPage/>} />
        {/* <Route path="*" element={<LoginPage isLoading={isLoading}/>} />*/}
        <Route path="public/events/:id/forms/create"
          element={<ResponseFormPage/>} />
        <Route path="public/events/:id/forms/thank-you"
          element={<ThanksPage/>} />
        <Route path="public/events/:id/qr"
          element={
            <QRCodePage title="Code QR"/>
          } />
      </Routes>
    </LangContextProvider>
  );
}
export default PublicRouter;
