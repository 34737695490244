import React from 'react';
import './styles.scss';

/**
 * Page qui contient les élément qui permets la connexion
 * @param {Boolean} isLoading savoir si la page est en reload
 * @return {JSX.Element} La page de connexion
 */
export default function UnauthorizedPage() {
  return (
    <div className="container">
      <h1>Vous n&apos;êtes pas autorisé.</h1>
    </div>
  );
};
