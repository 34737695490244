import axios from 'axios';
import jwt from 'jwt-decode';

axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

export const expire = {time: null};

/**
 * Mettre en place la gestion d'accès aux routes (authentification)
 * @param {NavigateFunction} navigate La fonction de navigation
 * @param {Function} logoutFunc permet de se déconnecter
 */
export function setupAuthRedirector(navigate, logoutFunc) {
  if (axios.interceptors.response.handlers.length > 0) return;
  axios.interceptors.response.use(async (res) => {
    return res;
  }, async (err) => {
    const status = err?.response?.status;
    switch (status) {
      case 401: // Non connecté
        // Naviguer à *, qui redirigera au login
        logoutFunc();
        navigate('/');
        break;
      case 403: // Connecté mais non autorisé
        // Naviguer à la page precedente
        navigate('/unauthorized');
        break;
      default:
        return Promise.reject(err);
    }
  });
}

/**
 * Permet d'ajouter' l'inteceptor authInterceptor
 */
export function addInterceptor() {
  if (axios.interceptors.request.handlers.length > 0) return;
  axios.interceptors.request.use(async (config) => {
    const currentDate = new Date();
    if (expire.time === null) return config;

    if (expire.time > currentDate.getTime()) {
      return config;
    }

    const refresh = axios.create();
    const res =
    await refresh.get(process.env.REACT_APP_BASEURL + '/auth/accessToken');
    config.headers.Authorization = `Bearer ${res.data.token}`;
    addTokenToDefaultHeaders(res.data.token);
    const userDecoded = jwt(res.data.token);
    expire.time = userDecoded.exp * 1000;

    return config;
  }, (error) => {
    return Promise.reject(error);
  });
}

/**
 * Permet de retirer les inteceptors
 */
export function removeInterceptors() {
  axios.interceptors.request.handlers = [];
  axios.interceptors.response.handlers = [];
}

/**
 * Fonction qui redéfinie l'entête des call axios
 * @param {String} token Le token d'authorisation
 */
export function addTokenToDefaultHeaders(token) {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
}

/**
 * Fonction qui supprime le token de l'entête des call axios
 */
export function removeTokenFromDefaultHeaders() {
  axios.defaults.headers.Authorization = undefined;
}
