import React, {useContext, useEffect, useState} from 'react';
import ToggleLanguageBar
  from '../components/ToggleLanguageBar';
import {Button, Container, Grid, Paper, Typography} from '@mui/material';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import eventsService from '../../events/services/events';
import {LangContext} from '../../global/contexts/LangContext';
import NoEventMessage from '../components/NoEventMessage';
import useLoading from '../../global/components/loader/useLoading';
/**
 * Page de remerciements vue après avoir envoyé un sondage
 * @return {JSX.Element}
 */
function ThanksPage() {
  const {id} = useParams();

  const navigate = useNavigate();

  const [imgSource, setImgSource] = useState('');
  const [thanksMessages, setThanksMessages] = useState({});
  const [gesMessages, setGesMessages] = useState({});
  const {setIsLoading} = useLoading();
  const [hasNoEvent, setHasNoEvent] = useState(false);

  const {getRes, getCurrentLang} = useContext(LangContext);
  const {state} = useLocation();

  useEffect(() => {
    setIsLoading(true);
    eventsService.getEventInfo(id).then((res) => {
      setImgSource(res.data.thanks_photo);
      setThanksMessages({
        FR: res.data.thanks_message_fr,
        EN: res.data.thanks_message_en,
      });

      if (state !== null && state.ges !== undefined) {
        setGesMessages({
          FR: state.ges != undefined ?
          `Votre déplacement a émis ${state.ges} kgCO₂ aller-retour.` : '',
          EN: state.ges != undefined ?
          `Your round trip to this event emitted ${state.ges} kgCO₂.` : '',
        });
      }

      if (res.status === 204) {
        setHasNoEvent(true);
      }
    }) .catch(() => {
      setHasNoEvent(true);
    }) .finally(()=>{
      setIsLoading(false);
    });
  }, []);

  const image = !(imgSource === null || imgSource == '') ?
  <img src={imgSource} alt={'Image de l\'évènement'}
    height="100%" width="100%" /> : null;

  if (hasNoEvent) {
    return <NoEventMessage/>;
  }

  return (
    <Container disableGutters={true} className='page-background'>
      <ToggleLanguageBar/>
      <Grid container justifyContent="center" alignItems="flex-start"
        spacing={2} sx={{'padding': '1rem'}}>
        <Grid item xs={12} md={7}>
          <Paper elevation={0} sx={{'padding': '1rem', 'textAlign': 'center'}}>
            <Typography variant={'h2'}>
              {thanksMessages[getCurrentLang()] === null ? getRes('thanks') :
                  thanksMessages[getCurrentLang()]}
            </Typography>
            <Typography variant={'h3'} sx={{'marginTop': '1.5rem'}}>
              {gesMessages[getCurrentLang()]}
            </Typography>
            <Button disableElevation
              onClick={()=> navigate(`/public/events/${id}/forms/create`) }
              variant="contained"
              sx={{'marginTop': '1.5rem', 'width': '90%', 'height': '3rem'}}>
              {getRes('back')}
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5} >
          <Paper elevation={0} sx={{'padding': '1rem', 'textAlign': 'center'}}>
            {image}
          </Paper>
        </Grid>
      </Grid>
    </Container>);
}

export default ThanksPage;
