import React from 'react';
import logo from '../../ressources/images/logo-cqeer-sm.png';
import {TextField, Button, InputAdornment, IconButton} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import useLoginForm from '../hooks/useLoginForm';
import './LoginPage.scss';
/**
 * Page qui contient les élément qui permets la connexion
 * @param {Boolean} isLoading savoir si la page est en reload
 * @return {JSX.Element} La page de connexion
 */
export default function LoginPage() {
  const {
    errorDisplay,
    canConnect,
    showPassword,
    setShowPassword,
    showErrorLogin,
    setShowErrorLogin,
    formik,
  } = useLoginForm();

  return (
    <Grid container sx={{height: {xs: '100%', sm: '100vh'}}} >
      <Grid item sm={6} className="LogoBox" container direction={'column'}>
        <img src={logo} alt={'Logo'} data-testid={'logoAuth'}/>
        <p data-testid={'titleLogoAuth'}>Calculateur GES événementiel</p>
      </Grid>
      <Grid item sm={6} className="LoginBox" container direction={'column'}>
        <h1 data-testid={'titleForm'}> Connexion</h1>
        <div className="login-form">
          <form onSubmit={formik.handleSubmit} data-testid={'form'} noValidate>
            <div className="form-username">
              <span className="form-label">Utilisateur</span>
              <TextField
                inputProps={{'data-testid': 'textFieldUsername'}}
                id="usernameTextField"
                name="username"
                size="small"
                required
                variant="outlined"
                type="text"
                error={formik.touched.username &&
                    Boolean(formik.errors.username)}
                onChange={formik.handleChange}
                value={formik.values.username}
              />
              {formik.touched.username && Boolean(formik.errors.username) ?(
                <div className="form-errors" data-testid={'errorUsername'}>
                  {formik.errors.username}
                </div>
              ) : null}
            </div>
            <div className="form-password">
              <span className="form-label">
                    Mot de passe
              </span>
              <TextField
                inputProps={{'data-testid': 'textFieldPassword'}}
                id="passwordTextField"
                name="password"
                size="small"
                required
                error={formik.touched.password &&
                    Boolean(formik.errors.password)}
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                onChange={formik.handleChange}
                value={formik.values.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="eyeIcon"
                        aria-label="Activer la visibilité du mot de passe"
                        onClick={()=>setShowPassword((prevValue) => !prevValue)}
                        edge="end"
                        sx={{'color': '#ed6b39'}} >
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {formik.touched.password && Boolean(formik.errors.password) ? (
                <div className="form-errors" data-testid={'errorPassword'}>
                  {formik.errors.password}
                </div>
              ) : null}
            </div>
            <div className="form-button">
              <Button data-testid="buttonSubmit" type="submit"
                variant="contained" disabled={!canConnect} >
                Connexion
              </Button>
            </div>
          </form>
          <div id="error-message-login">
            <Collapse in={showErrorLogin}>
              <Alert action={
                <IconButton data-testid="btnCloseError" aria-label="close"
                  color="inherit" size="small" onClick={() => {
                    setShowErrorLogin(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{mb: 2}}
              severity="error"
              >
                {errorDisplay}
              </Alert>
            </Collapse>
          </div>
        </div>
        <p data-testid={'crtText'}>
            Copyright © 2022 • Tous droits réservés<br/>
            Conseil québécois des événements écoresponsables
        </p>
      </Grid>
    </Grid>
  );
};
