import React, {useState, useEffect, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import userServices from '../services/users';
import useUserForm from '../hooks/useUserForm';
import {AuthContext} from '../../auth/contexts/AuthContext';
import UnauthorizedPage from '../../global/pages/unauthorized';
import {createEmptyUser} from '../factory/user';
import AlertMessage from '../components/AlertMessage';
import UserForm from '../components/UserForm';
import useLoading from '../../global/components/loader/useLoading';
import {Container, Typography} from '@mui/material';

/**
 * Page de modification d'un utilisateur
 * @return {JSX.Element} Le contenu JSX.
 */
export default function EditUserPage() {
  const navigate = useNavigate();
  const {id} = useParams();
  const {user} = useContext(AuthContext);
  const {setIsLoading} = useLoading();
  const [initialValue, setInitialValue] = useState(createEmptyUser());
  const [alert, setAlert] = useState(undefined);
  /**
   * Soumettre la modification d'un utilisateur
   * @param {*} values Les valeurs du formulaire
   */
  function submitAction(values) {
    setIsLoading(true);
    if (!values.password && !values.confirmPassword) {
      delete values.password;
      delete values.confirmPassword;
    }
    userServices.sendUpdateUser(id, values).then(() => {
      setAlert(undefined);
      navigate('/users');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    setIsLoading(true);
    userServices.getUser(id).then((res) => {
      setInitialValue({
        login: res.data.login,
        name: res.data.name,
        password: '',
        confirmPassword: '',
        role_id: res.data.role.id,
        info: res.data.info || '',
        remaining_calls: res.data.remaining_calls,
      });
    }).catch(() => {
      setAlert({
        severity: 'error',
        message: 'Une erreur est survenue.',
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  const userHook = useUserForm(submitAction, initialValue, 'edit');

  return (
    <Container>
      <Typography variant={'h1'} >Modifier un utilisateur</Typography>
      {id == user.id ? // Can't modify yourself. (use page profil for that)
        <UnauthorizedPage /> :
        <UserForm
          submitText='Modifier le compte'
          isOrganizer={(initialValue.role_id === 4)}
          {...userHook}/>
      }
      <AlertMessage alert={alert} />
    </Container>
  );
}
