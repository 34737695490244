import {Alert} from '@mui/material';
import React from 'react';
import propTypes from 'prop-types';

/**
 *
 * @param {object} syntax = {severity:'string, message:'string}
 * @return {jsx}
 */
function AlertMessage({alert}) {
  return alert ? (
    <div className="password-message" key={alert.message}>
      <Alert severity={alert.severity}>
        {alert.message}
      </Alert>
    </div>
) : null;
}

AlertMessage.propTypes = {
  alert: propTypes.object,
};


export default AlertMessage;
