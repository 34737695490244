/**
 * Génère un utilisateur vide
 * @return {object} New empty user for the form
 */
export function createEmptyUser() {
  return {
    login: '',
    name: '',
    password: '',
    confirmPassword: '',
    role_id: 0,
    info: '',
    remaining_calls: 0,
  };
}
