/**
 * Génère un utilisateur vide
 * @return {object} New empty user for the form
 */
export function createEmptyEvent({id, name}) {
  return {
    eventName: '',
    eventLogoName: 'Aucune photo',
    eventLogoImageFile: null,
    eventOrganizerId: id,
    eventOrganizerName: name,
    eventDateStart: null,
    rawEventDateStart: null,
    eventDateEnd: null,
    rawEventDateEnd: null,
    eventAddress: '',
    eventDescriptionEng: '',
    eventDescriptionFr: '',
    eventThanksEng: '',
    eventThanksFr: '',
    eventThanksImageName: 'Aucune photo',
    eventThanksImageFile: null,
    otherInfo: '',
    numberParticipant: 0, // dans la docs de backend, aucune mention
    showGES: false, // dans la docs de backend, aucune mentio
  };
}
