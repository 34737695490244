import React from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import {useFormik} from 'formik';
import {useNavigate, createSearchParams} from 'react-router-dom';
import {Stack} from '@mui/material';

/**
 * Component de la barre de recherche
 * @return {JSX.Element} la barre de recherche
 */
export default function SearchBar() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      if (values.search !== '') {
        navigate({
          pathname: 'search',
          search: `?${createSearchParams({
            keywords: values.search,
          })}`,
        });
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Stack direction="row" paddingBottom={'1rem'}>
        <TextField name='search'
          label="Rechercher..."
          variant="standard"
          value={formik.values.search}
          inputProps={{'data-testid': 'searchInput'}}
          onChange={formik.handleChange} />
        <IconButton sx={{mt: 2}} onClick={formik.handleSubmit}>
          <SearchIcon sx={{color: 'action.active'}} />
        </IconButton>
      </Stack>
    </form>
  );
}
