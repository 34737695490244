import React, {useState, createContext, useContext} from 'react';
import Spinner from './Spinner';
import propTypes from 'prop-types';

const LoadingContext = createContext({
  isLoading: false,
  setIsLoading: null,
});

/**
 * Provider of the loading context
 * @param {object} children
 * @return {jsx}
 */
export function LoadingProvider({children}) {
  const [isLoading, setIsLoading] =
    useState(process.env.REACT_APP_MODE !== 'test');
  const value = {isLoading, setIsLoading};
  return (
    <LoadingContext.Provider value={value}>
      <div className={isLoading ? 'active' : 'hidden'}>
        <Spinner />;
      </div>
      {children}
    </LoadingContext.Provider>
  );
}

LoadingProvider.propTypes = {
  children: propTypes.any,
};


/**
 * Costum hook to use de loading
 * @return {object}
 */
export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}

export default useLoading;
