import {ToggleButton, Typography, Stack} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Bouton toggle pour choisir le mode de transport.
 * @return {JSX.Element}
 * @constructor
 */
function TransportToggleButton( {transportType, selected, handleClick, Icon}) {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <ToggleButton
        value={transportType}
        aria-label={transportType}
        onChange={handleClick}
        sx={{
          'backgroundColor': selected ? '#ed6b39' : '#FFF',
          'border': '4px solid',
          'padding': '15px',
          'borderRadius': '5px',
          'borderColor': '#ed6b39',
          '&:hover': {backgroundColor: '#ed6b39'},
        }}
      >
        <Icon sx={{'color': selected ? '#FFF': '#000'}} />
      </ToggleButton>
      <Typography align={'center'} variant={'subtitle2'} >
        {transportType}
      </Typography>
    </Stack>
  );
}

TransportToggleButton.propTypes = {
  transportType: PropTypes.string,
  selected: PropTypes.bool,
  handleClick: PropTypes.func,
  Icon: PropTypes.object,
};

export default TransportToggleButton;
