import React, {useContext} from 'react';
import UsersList from '../components/UsersList';
import {Button, Container, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router';
import {AuthContext} from '../../auth/contexts/AuthContext';

/**
 * La page des utilisateurs qui charge la liste des users de l'API
 *
 * @author Samuel Barriault
 * @return {JSX.Element}
 */
function UsersListPage() {
  const navigate = useNavigate();
  const {user} = useContext(AuthContext);

  return (
    <Container data-testid="users-screen">
      <Grid container justifyContent="space-between"
        textAlign="center" marginBottom={3}>
        <Grid item sm={6}>
          <Typography variant={'h1'} data-testid='usersListTitle'>
          Utilisateurs
          </Typography>
        </Grid>

        {user.level <= 1 ? (
        <Grid item sm={6}>
          <Button onClick={()=>navigate('/users/create')} variant='contained'
            data-testid='createUserBtn'>
              Ajouter un utilisateur
          </Button>
        </Grid>
        ): null }
      </Grid>
      <UsersList />
    </Container>
  );
}

export default UsersListPage;
