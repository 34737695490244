import React from 'react';
import TransportDetail from '../components/TransportDetail';


/**
 * Fonction qui retourne la page détails d'un transport
 * @return {JSX.Element}
 */
export default function DetailTransportPage() {
  return (
    <TransportDetail/>
  );
}
