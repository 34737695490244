import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import useLoading from '../../global/components/loader/useLoading';
import usersService from '../services/users';

/**
 * Costum hook for user detail operation
 * @return {object}
 */
function useUserDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {setIsLoading} = useLoading();
  const [userDetail, setUserDetail] = useState({
    info: '',
    role: {},
    remaining_calls: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    usersService.getUser(id).then((res) => {
      setUserDetail(res.data);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  /**
   * Permet de trigger le modal de suppression d'un utilisateur.
   */
  function onDelete() {
    setIsLoading(true);
    usersService.deleteUser(id).then(() => {
      navigate('/users');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  /**
   * Fonction qui permet d'aller sur la page de modification d'un utilisateur
   */
  function onEdit() {
    navigate(`/users/${id}/edit`);
  }


  return {onEdit, onDelete, userDetail};
}

export default useUserDetail;
