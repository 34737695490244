import React, {useState, useContext, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import {Button, Divider, IconButton} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import './styles.scss';
import logo from '../../../ressources/images/logo-cqeer.png';
import {AuthContext} from '../../../auth/contexts/AuthContext';
import {Link, useNavigate} from 'react-router-dom';
import SearchBar from '../../../searchs/components/SearchBar';
import Jeton from './Jeton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
/**
 * Component de la barre de navigation
 * @return {JSX.Element} la barre de navigation
 */
function Navbar() {
  const navigate = useNavigate();
  const {handleLogout, displayNavbar, user} = useContext(AuthContext);

  const [pages, setPage] = useState(setMenuFromPermisionLevel(user.level));
  useEffect(()=>{
    setPage(setMenuFromPermisionLevel(user.level));
  }, [user.level]);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);

  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  return (
    <AppBar data-testid="Navbar" position="static" id="navbar"
      className={displayNavbar ? null : 'none'}>
      <Toolbar disableGutters variant='dense'>
        {/* Logo à droite medium*/}
        <Typography variant="h5" noWrap
          component="div" sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
        >
          <img src={logo} className='logoNav' />
        </Typography>

        {/* menu compact small seulement*/}
        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
          <IconButton
            size="large"
            aria-label="Navigation"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: {xs: 'block', md: 'none'},
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page.id} onClick={handleCloseNavMenu}
                component={Link} to={'/' + page.url}
                data-testid={page.url}
              >
                <Typography textAlign="center" fontWeight="bold"
                  sx={{my: 1, color: '#4B4B4B', fontSize: '1.2em'}}>
                  {page.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>


        {/* menu étendu medium */}
        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
          {pages.map((page) => (
            <Button
              key={page.id}
              onClick={handleCloseNavMenu}
              sx={{backgroundColor: 'transparent !important',
                my: 2, color: '#4B4B4B', display: 'block',
                textDecoration: 'none', fontSize: '1em',
              }}
              component={Link}
              to={'/' + page.url}
              data-testid={page.url}
            >
              {page.name}
            </Button>
          ))}
        </Box>


        {/* jeton restant*/}
        <Jeton/>

        {/* Barre de recherche */}
        <SearchBar />

        {/* Menu déroulant */}
        <Box sx={{flexGrow: 0}}>
          <IconButton
            size="large"
            onClick={handleOpenUserMenu}
            sx={{
              my: 2, color: '#4B4B4B', display: 'block',
              textDecoration: 'none', fontSize: '1em',
            }} >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            sx={{mt: '45px'}}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => {
              if (setting.name === 'Divider') {
                return (
                  <Divider key={setting.id} />
                );
              }

              if (setting.name === 'Déconnexion') {
                return (
                  <MenuItem key={setting.id}
                    onClick={() => {
                      handleLogout();
                      navigate('/');
                    }}
                    data-testid={setting.url}
                  >
                    <Typography textAlign="center" fontWeight="bold"
                      sx={{color: '#4B4B4B', fontSize: '1.2em'}}>
                      {setting.name}
                    </Typography>
                  </MenuItem>);
              }

              return (
                <MenuItem key={setting.id} onClick={handleCloseUserMenu}
                  component={Link} to={'/' + setting.url}
                  data-testid={setting.url}
                >
                  <Typography textAlign="center" fontWeight="bold"
                    sx={{color: '#4B4B4B', fontSize: '1.2em'}}>
                    {setting.name}
                  </Typography>
                </MenuItem>);
            })}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

/**
 * Build an array for the available menu
 * @param {int} level
 * @return {object}
 */
function setMenuFromPermisionLevel(level) {
  const pages = [];

  if (level <= 1) {
    pages.push({id: 1, name: 'Véhicules', url: 'transports'});
  }

  if (level <= 2) {
    pages.push({id: 2, name: 'Utilisateurs', url: 'users'});
  }

  pages.push({id: 3, name: 'Événements', url: 'events'});

  return pages;
}

const settings = [
  {id: 5, name: 'Modifier', url: 'profile'},
  {id: 6, name: 'Divider', url: ''},
  {id: 7, name: 'Déconnexion', url: 'logout'},
];

export default Navbar;
