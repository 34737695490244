import React from 'react';
import Grid from '@mui/material/Grid';
import './spinner.scss';
import Logo from '../../../ressources/images/spinner.png';

/**
 * Router général pour la navigation
 * @return {JSX.Element} Router
 * @constructor
 */
export default function Spinner() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{minHeight: '100vh'}}
    >

      <Grid item xs={3}>
        <img src={Logo} alt="logo" className='spinner' data-testid="spinner" />
      </Grid>

    </Grid>
  );
}
